import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { OrderItem, ShipmentOrderItem, User, Order } from "./";

export type ShipmentFields = Partial<FieldProps<Shipment>>;

/**
 * Generated class for Shipment__c
 */
export class Shipment extends RestObject {
    @sField({ apiName: 'OrderItems__r', createable: false, updateable: false, required: false, reference: () => { return OrderItem }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'OrderItems__r', externalId: false })
    public orderItems?: OrderItem[];
    @sField({ apiName: 'ShipmentOrderItems__r', createable: false, updateable: false, required: false, reference: () => { return ShipmentOrderItem }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'ShipmentOrderItems__r', externalId: false })
    public shipmentOrderItems?: ShipmentOrderItem[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'OwnerId', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public ownerId?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Shipment Order ID', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'CarrierServiceLevel__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Carrier Service Level', externalId: false })
    public carrierServiceLevel?: string | null;
    /**
     * Time the reviewer claimed the shipment for QA
     */
    @sField({ apiName: 'ClaimTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Claim Time', externalId: false })
    public claimTime?: Date | null;
    @sField({ apiName: 'Claim__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Claim', externalId: false })
    public readonly claim?: string | null;
    /**
     * Any errors encountered while generating this shipment will be listed here. If any errors are listed, problems must be corrected before this shipment can be regenerated and submitted.
     */
    @sField({ apiName: 'Errors__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Errors', externalId: false })
    public errors?: string | null;
    @sField({ apiName: 'FulfillerShippingCharge__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Fulfiller Shipping Charge', externalId: false })
    public fulfillerShippingCharge?: number | null;
    @sField({ apiName: 'Fulfiller__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Fulfiller', externalId: false })
    public fulfiller?: string | null;
    @sField({ apiName: 'GenerateXmlLink__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Generate XML Link', externalId: false })
    public readonly generateXmlLink?: string | null;
    /**
     * Last time the assets for this shipment were generated
     */
    @sField({ apiName: 'GenerationTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Generation Time', externalId: false })
    public generationTime?: Date | null;
    @sField({ apiName: 'IsReviewableByCurrentUser__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is Reviewable By Current User', externalId: false })
    public readonly isReviewableByCurrentUser?: string | null;
    @sField({ apiName: 'OrderId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'OrderId', externalId: true })
    public orderId?: string | null;
    @sField({ apiName: 'OrderRequestXmlUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Order Request XML URL', externalId: false })
    public orderRequestXmlUrl?: string | null;
    @sField({ apiName: 'OrderTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Order Time', externalId: false })
    public orderTime?: Date | null;
    @sField({ apiName: 'Order__r', createable: false, updateable: false, required: false, reference: () => { return Order }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order', externalId: false })
    public order?: Order;
    @sField({ apiName: 'Order__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order', externalId: false })
    public orderSId?: string | null;
    @sField({ apiName: 'PrintingTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Printing Time', externalId: false })
    public printingTime?: Date | null;
    @sField({ apiName: 'Reviewer__r', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer', externalId: false })
    public reviewer?: User;
    @sField({ apiName: 'Reviewer__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer', externalId: false })
    public reviewerId?: string | null;
    /**
     * If populated, this will ensure a shipment doesn't go out more than a few days before this date. If blank, this shipment will go out ASAP.
     */
    @sField({ apiName: 'ScheduledShipDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Scheduled Ship Date', externalId: false })
    public scheduledShipDate?: CalendarDate | null;
    @sField({ apiName: 'ShipTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Ship Time', externalId: false })
    public shipTime?: Date | null;
    @sField({ apiName: 'ShipmentPostbackXml__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Shipment Postback XML', externalId: false })
    public shipmentPostbackXml?: string | null;
    @sField({ apiName: 'SpreadsheetUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Spreadsheet URL', externalId: false })
    public spreadsheetUrl?: string | null;
    @sField({ apiName: 'Status__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Status', externalId: false })
    public status?: string | null;
    /**
     * Time the shipment was approved by QA and submitted to the printer
     */
    @sField({ apiName: 'SubmittedToPrinterTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Submitted To Printer Time', externalId: false })
    public submittedToPrinterTime?: Date | null;
    @sField({ apiName: 'TrackingNumber__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Tracking Number', externalId: false })
    public trackingNumber?: string | null;
    @sField({ apiName: 'Weight__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Weight', externalId: false })
    public weight?: number | null;
    @sField({ apiName: 'Priority__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Priority', externalId: false })
    public readonly priority?: number | null;
    @sField({ apiName: 'Carrier__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Carrier', externalId: false })
    public readonly carrier?: string | null;
    @sField({ apiName: 'TrackingUrl__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Tracking URL', externalId: false })
    public readonly trackingUrl?: string | null;
    /**
     * Total number of items shipped to the order shipping address
     */
    @sField({ apiName: 'ShippedQuantity__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Shipped Quantity', externalId: false })
    public shippedQuantity?: number | null;
    /**
     * Number of cards mailed directly to recipients in this shipment
     */
    @sField({ apiName: 'MailedQuantity__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Mailed Quantity', externalId: false })
    public mailedQuantity?: number | null;
    @sField({ apiName: 'CustomerEmail__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.EMAIL, salesforceLabel: 'Customer Email', externalId: false })
    public customerEmail?: string | null;
    @sField({ apiName: 'NotShippedComments__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Not Shipped Comments', externalId: false })
    public notShippedComments?: string | null;
    @sField({ apiName: 'DaysToShipSinceSubmitted__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days to Ship Since Submitted', externalId: false })
    public readonly daysToShipSinceSubmitted?: number | null;
    @sField({ apiName: 'DaysToShipSinceOrdered__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days to Ship Since Ordered', externalId: false })
    public readonly daysToShipSinceOrdered?: number | null;
    /**
     * Difference between actual ship date and scheduled ship date in days. Negative means we shipped early.
     */
    @sField({ apiName: 'ScheduledVsActualShipDateDelta__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Scheduled vs Actual Ship Date Delta', externalId: false })
    public readonly scheduledVsActualShipDateDelta?: number | null;
    /**
     * Fulfiller's internal order ID. Populated when the shipment is submitted to Mouqin so we can refer to it using their internal ID if we need to contact them.
     */
    @sField({ apiName: 'FulfillerId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Fulfiller ID', externalId: false })
    public fulfillerId?: string | null;
    @sField({ apiName: 'Has_Dash_PC_Update__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Has Dash PC Update', externalId: false })
    public hasDashPcUpdate?: boolean | null;
    @sField({ apiName: 'OrderItemCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Number of Order Items', externalId: false })
    public readonly orderItemCount?: number | null;
    @sField({ apiName: 'LastBatchJobTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Batch Job Time', externalId: false })
    public lastBatchJobTime?: Date | null;
    /**
     * Disables email notifications. Useful to avoid confusing the customer if we're manually updating a shipment to Shipped, long after it was actually shipped.
     */
    @sField({ apiName: 'MuteEmails__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Mute Emails', externalId: false })
    public muteEmails?: boolean | null;

    constructor(fields?: ShipmentFields, restInstance?: Rest) {
        super('Shipment__c', restInstance);
        this.orderItems = void 0;
        this.shipmentOrderItems = void 0;
        this.id = void 0;
        this.ownerId = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.carrierServiceLevel = void 0;
        this.claimTime = void 0;
        this.claim = void 0;
        this.errors = void 0;
        this.fulfillerShippingCharge = void 0;
        this.fulfiller = void 0;
        this.generateXmlLink = void 0;
        this.generationTime = void 0;
        this.isReviewableByCurrentUser = void 0;
        this.orderId = void 0;
        this.orderRequestXmlUrl = void 0;
        this.orderTime = void 0;
        this.order = void 0;
        this.orderSId = void 0;
        this.printingTime = void 0;
        this.reviewer = void 0;
        this.reviewerId = void 0;
        this.scheduledShipDate = void 0;
        this.shipTime = void 0;
        this.shipmentPostbackXml = void 0;
        this.spreadsheetUrl = void 0;
        this.status = void 0;
        this.submittedToPrinterTime = void 0;
        this.trackingNumber = void 0;
        this.weight = void 0;
        this.priority = void 0;
        this.carrier = void 0;
        this.trackingUrl = void 0;
        this.shippedQuantity = void 0;
        this.mailedQuantity = void 0;
        this.customerEmail = void 0;
        this.notShippedComments = void 0;
        this.daysToShipSinceSubmitted = void 0;
        this.daysToShipSinceOrdered = void 0;
        this.scheduledVsActualShipDateDelta = void 0;
        this.fulfillerId = void 0;
        this.hasDashPcUpdate = void 0;
        this.orderItemCount = void 0;
        this.lastBatchJobTime = void 0;
        this.muteEmails = void 0;
        this.__UUID = Shipment.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Shipment__c' = 'Shipment__c';
    public readonly _TYPE_: 'Shipment__c' = 'Shipment__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Shipment>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Shipment.getPropertiesMeta<FieldProps<Shipment>, Shipment>(Shipment)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Shipment>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Shipment[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Shipment, qryParam) : qryParam;
        return await RestObject.query<Shipment>(Shipment, qry, opts);

    }

    public static fromSFObject(sob: SObject): Shipment {
        return new Shipment().mapFromQuery(sob);
    }
}
