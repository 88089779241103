import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, Contact } from "./";

export type SampleRequestFields = Partial<FieldProps<SampleRequest>>;

/**
 * Generated class for SampleRequest__c
 */
export class SampleRequest extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Sample Request Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'FirstName__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'First Name', externalId: false })
    public firstName?: string | null;
    @sField({ apiName: 'EventStyle__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Event Style', externalId: false })
    public eventStyle?: string | null;
    @sField({ apiName: 'LastName__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Last Name', externalId: false })
    public lastName?: string | null;
    @sField({ apiName: 'Type__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Type', externalId: false })
    public type?: string | null;
    @sField({ apiName: 'Address1__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Address 1', externalId: false })
    public address1?: string | null;
    @sField({ apiName: 'Address2__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Address 2', externalId: false })
    public address2?: string | null;
    @sField({ apiName: 'Address3__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Address 3', externalId: false })
    public address3?: string | null;
    @sField({ apiName: 'City__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'City', externalId: false })
    public city?: string | null;
    @sField({ apiName: 'State__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'State', externalId: false })
    public state?: string | null;
    @sField({ apiName: 'ZipCode__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Zip Code', externalId: false })
    public zipCode?: string | null;
    /**
     * Date the sample pertains to. For example, a wedding date.
     */
    @sField({ apiName: 'EventDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Event Date', externalId: false })
    public eventDate?: CalendarDate | null;
    @sField({ apiName: 'Phone__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PHONE, salesforceLabel: 'Phone', externalId: false })
    public phone?: string | null;
    /**
     * Date the sample was sent to the customer
     */
    @sField({ apiName: 'FulfillmentDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Fulfillment Date', externalId: false })
    public fulfillmentDate?: CalendarDate | null;
    @sField({ apiName: 'GuestCount__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Number of Guests', externalId: false })
    public guestCount?: number | null;
    @sField({ apiName: 'Customer__r', createable: false, updateable: false, required: false, reference: () => { return Contact }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customer?: Contact;
    @sField({ apiName: 'Customer__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customerId?: string | null;
    /**
     * Checked if we can fulfill the sample. For free samples, this is initially checked. For paid samples, this is initially unchecked, then we update it to checked once the sample is paid for.
     */
    @sField({ apiName: 'IsFulfillable__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Fulfillable', externalId: false })
    public isFulfillable?: boolean | null;

    constructor(fields?: SampleRequestFields, restInstance?: Rest) {
        super('SampleRequest__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.firstName = void 0;
        this.eventStyle = void 0;
        this.lastName = void 0;
        this.type = void 0;
        this.address1 = void 0;
        this.address2 = void 0;
        this.address3 = void 0;
        this.city = void 0;
        this.state = void 0;
        this.zipCode = void 0;
        this.eventDate = void 0;
        this.phone = void 0;
        this.fulfillmentDate = void 0;
        this.guestCount = void 0;
        this.customer = void 0;
        this.customerId = void 0;
        this.isFulfillable = void 0;
        this.__UUID = SampleRequest.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'SampleRequest__c' = 'SampleRequest__c';
    public readonly _TYPE_: 'SampleRequest__c' = 'SampleRequest__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<SampleRequest>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : SampleRequest.getPropertiesMeta<FieldProps<SampleRequest>, SampleRequest>(SampleRequest)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<SampleRequest>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<SampleRequest[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(SampleRequest, qryParam) : qryParam;
        return await RestObject.query<SampleRequest>(SampleRequest, qry, opts);

    }

    public static fromSFObject(sob: SObject): SampleRequest {
        return new SampleRequest().mapFromQuery(sob);
    }
}
