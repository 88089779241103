import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { OrderItem, Project, Proof, User, ProofFormat, Category } from "./";

export type Product2Fields = Partial<FieldProps<Product2>>;

/**
 * Generated class for Product2
 */
export class Product2 extends RestObject {
    @sField({ apiName: 'OrderItems__r', createable: false, updateable: false, required: false, reference: () => { return OrderItem }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'OrderItems__r', externalId: false })
    public orderItems?: OrderItem[];
    @sField({ apiName: 'Projects__r', createable: false, updateable: false, required: false, reference: () => { return Project }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Projects__r', externalId: false })
    public projects?: Project[];
    @sField({ apiName: 'Proofs__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proofs__r', externalId: false })
    public proofs?: Proof[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Product ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Product Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'ProductCode', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Product Code', externalId: false })
    public productCode?: string | null;
    @sField({ apiName: 'Description', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Product Description', externalId: false })
    public description?: string | null;
    @sField({ apiName: 'IsActive', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Active', externalId: false })
    public isActive?: boolean | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'Family', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Product Family', externalId: false })
    public family?: string | null;
    @sField({ apiName: 'ExternalDataSourceId', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'External Data Source ID', externalId: false })
    public externalDataSourceId?: string | null;
    @sField({ apiName: 'ExternalId', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'External ID', externalId: false })
    public externalId?: string | null;
    @sField({ apiName: 'DisplayUrl', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Display URL', externalId: false })
    public displayUrl?: string | null;
    @sField({ apiName: 'QuantityUnitOfMeasure', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Quantity Unit Of Measure', externalId: false })
    public quantityUnitOfMeasure?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'IsArchived', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Archived', externalId: false })
    public readonly isArchived?: boolean | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'StockKeepingUnit', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Product SKU', externalId: false })
    public stockKeepingUnit?: string | null;
    @sField({ apiName: 'Code__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Code', externalId: true })
    public itemCode?: string | null;
    @sField({ apiName: 'Format__r', createable: false, updateable: false, required: false, reference: () => { return ProofFormat }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Format', externalId: false })
    public format?: ProofFormat;
    @sField({ apiName: 'Format__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Format', externalId: false })
    public formatId?: string | null;
    @sField({ apiName: 'HeroImageUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Hero Image URL', externalId: false })
    public heroImageUrl?: string | null;
    @sField({ apiName: 'HugeImageUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Huge Image URL', externalId: false })
    public hugeImageUrl?: string | null;
    @sField({ apiName: 'Id__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Id', externalId: true })
    public itemId?: number | null;
    @sField({ apiName: 'IsCard__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Card', externalId: false })
    public isCard?: boolean | null;
    @sField({ apiName: 'IsHorizontal__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Horizontal', externalId: false })
    public isHorizontal?: boolean | null;
    @sField({ apiName: 'IsOnlySendToSelf__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Only Send To Self', externalId: false })
    public isOnlySendToSelf?: boolean | null;
    @sField({ apiName: 'IsPersonalizable__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Personalizable', externalId: false })
    public isPersonalizable?: boolean | null;
    @sField({ apiName: 'IsPhotoCard__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Photo Card', externalId: false })
    public isPhotoCard?: boolean | null;
    @sField({ apiName: 'IsVertical__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Vertical', externalId: false })
    public isVertical?: boolean | null;
    @sField({ apiName: 'IsWallArt__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Wall Art', externalId: false })
    public isWallArt?: boolean | null;
    @sField({ apiName: 'Orientation__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Orientation', externalId: false })
    public readonly orientation?: string | null;
    /**
     * Number of photos on the card
     */
    @sField({ apiName: 'PhotoCount__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Photo Count', externalId: false })
    public photoCount?: number | null;
    @sField({ apiName: 'ProductType__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Product Type', externalId: false })
    public productType?: string | null;
    @sField({ apiName: 'SeoUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'SEO URL', externalId: false })
    public seoUrl?: string | null;
    @sField({ apiName: 'SmallThumbnailUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Small Thumbnail URL', externalId: false })
    public smallThumbnailUrl?: string | null;
    @sField({ apiName: 'HugeImage__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Huge Image', externalId: false })
    public readonly hugeImage?: string | null;
    @sField({ apiName: 'CanonicalCategory__r', createable: false, updateable: false, required: false, reference: () => { return Category }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Canonical Category', externalId: false })
    public canonicalCategory?: Category;
    @sField({ apiName: 'CanonicalCategory__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Canonical Category', externalId: false })
    public canonicalCategoryId?: string | null;
    @sField({ apiName: 'MajorCategory__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Major Category', externalId: false })
    public readonly majorCategory?: string | null;
    @sField({ apiName: 'MinorCategory__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Minor Category', externalId: false })
    public readonly minorCategory?: string | null;
    @sField({ apiName: 'FormatCode__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Format Code', externalId: false })
    public readonly formatCode?: string | null;

    constructor(fields?: Product2Fields, restInstance?: Rest) {
        super('Product2', restInstance);
        this.orderItems = void 0;
        this.projects = void 0;
        this.proofs = void 0;
        this.id = void 0;
        this.name = void 0;
        this.productCode = void 0;
        this.description = void 0;
        this.isActive = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.family = void 0;
        this.externalDataSourceId = void 0;
        this.externalId = void 0;
        this.displayUrl = void 0;
        this.quantityUnitOfMeasure = void 0;
        this.isDeleted = void 0;
        this.isArchived = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.stockKeepingUnit = void 0;
        this.itemCode = void 0;
        this.format = void 0;
        this.formatId = void 0;
        this.heroImageUrl = void 0;
        this.hugeImageUrl = void 0;
        this.itemId = void 0;
        this.isCard = void 0;
        this.isHorizontal = void 0;
        this.isOnlySendToSelf = void 0;
        this.isPersonalizable = void 0;
        this.isPhotoCard = void 0;
        this.isVertical = void 0;
        this.isWallArt = void 0;
        this.orientation = void 0;
        this.photoCount = void 0;
        this.productType = void 0;
        this.seoUrl = void 0;
        this.smallThumbnailUrl = void 0;
        this.hugeImage = void 0;
        this.canonicalCategory = void 0;
        this.canonicalCategoryId = void 0;
        this.majorCategory = void 0;
        this.minorCategory = void 0;
        this.formatCode = void 0;
        this.__UUID = Product2.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Product2' = 'Product2';
    public readonly _TYPE_: 'Product2' = 'Product2';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Product2>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Product2.getPropertiesMeta<FieldProps<Product2>, Product2>(Product2)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Product2>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Product2[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Product2, qryParam) : qryParam;
        return await RestObject.query<Product2>(Product2, qry, opts);

    }

    public static fromSFObject(sob: SObject): Product2 {
        return new Product2().mapFromQuery(sob);
    }
}
