import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, Order } from "./";

export type OrderAdjustmentFields = Partial<FieldProps<OrderAdjustment>>;

/**
 * Generated class for OrderAdjustment__c
 */
export class OrderAdjustment extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Order Adjustment Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'Order__r', createable: false, updateable: false, required: false, reference: () => { return Order }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order', externalId: false })
    public order?: Order;
    @sField({ apiName: 'Order__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order', externalId: false })
    public orderId?: string | null;
    /**
     * Only populated if the shipping type was changed
     */
    @sField({ apiName: 'ShippingType__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Shipping Type', externalId: false })
    public shippingType?: string | null;
    @sField({ apiName: 'Shipping__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Shipping', externalId: false })
    public shipping?: number | null;
    @sField({ apiName: 'Tax__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Tax', externalId: false })
    public tax?: number | null;
    @sField({ apiName: 'Subtotal__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Subtotal', externalId: false })
    public subtotal?: number | null;
    @sField({ apiName: 'Discount__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Discount', externalId: false })
    public discount?: number | null;
    @sField({ apiName: 'Total__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Total', externalId: false })
    public readonly total?: number | null;
    @sField({ apiName: 'Id__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Id', externalId: true })
    public orderAdjustmentId?: string | null;
    @sField({ apiName: 'CustomerEmail__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.EMAIL, salesforceLabel: 'Customer Email', externalId: false })
    public customerEmail?: string | null;

    constructor(fields?: OrderAdjustmentFields, restInstance?: Rest) {
        super('OrderAdjustment__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.order = void 0;
        this.orderId = void 0;
        this.shippingType = void 0;
        this.shipping = void 0;
        this.tax = void 0;
        this.subtotal = void 0;
        this.discount = void 0;
        this.total = void 0;
        this.orderAdjustmentId = void 0;
        this.customerEmail = void 0;
        this.__UUID = OrderAdjustment.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'OrderAdjustment__c' = 'OrderAdjustment__c';
    public readonly _TYPE_: 'OrderAdjustment__c' = 'OrderAdjustment__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<OrderAdjustment>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : OrderAdjustment.getPropertiesMeta<FieldProps<OrderAdjustment>, OrderAdjustment>(OrderAdjustment)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<OrderAdjustment>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<OrderAdjustment[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(OrderAdjustment, qryParam) : qryParam;
        return await RestObject.query<OrderAdjustment>(OrderAdjustment, qry, opts);

    }

    public static fromSFObject(sob: SObject): OrderAdjustment {
        return new OrderAdjustment().mapFromQuery(sob);
    }
}
