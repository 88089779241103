import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, Proof } from "./";

export type ProofSpecialInstructionFields = Partial<FieldProps<ProofSpecialInstruction>>;

/**
 * Generated class for ProofSpecialInstruction__c
 */
export class ProofSpecialInstruction extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'Proof__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof', externalId: false })
    public proof?: Proof;
    @sField({ apiName: 'Proof__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof', externalId: false })
    public proofId?: string | null;
    /**
     * Add your description of the special instruction
     */
    @sField({ apiName: 'Description__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Description', externalId: false })
    public description?: string | null;
    /**
     * Type of Special Instructions. If you need to include multiple special instructions, you need to create multiple Special Instruction tickets. Select one Type and complete the description of the instruction. Then repeat and create another one.
     */
    @sField({ apiName: 'Type__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Type', externalId: false })
    public type?: string | null;
    /**
     * Check this box ONLY when the entire task is completed
     */
    @sField({ apiName: 'Complete__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Complete', externalId: false })
    public complete?: boolean | null;

    constructor(fields?: ProofSpecialInstructionFields, restInstance?: Rest) {
        super('ProofSpecialInstruction__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.proof = void 0;
        this.proofId = void 0;
        this.description = void 0;
        this.type = void 0;
        this.complete = void 0;
        this.__UUID = ProofSpecialInstruction.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofSpecialInstruction__c' = 'ProofSpecialInstruction__c';
    public readonly _TYPE_: 'ProofSpecialInstruction__c' = 'ProofSpecialInstruction__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofSpecialInstruction>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofSpecialInstruction.getPropertiesMeta<FieldProps<ProofSpecialInstruction>, ProofSpecialInstruction>(ProofSpecialInstruction)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofSpecialInstruction>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofSpecialInstruction[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofSpecialInstruction, qryParam) : qryParam;
        return await RestObject.query<ProofSpecialInstruction>(ProofSpecialInstruction, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofSpecialInstruction {
        return new ProofSpecialInstruction().mapFromQuery(sob);
    }
}
