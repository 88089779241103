import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { ProofRevisionSurface, User, ProofFormat } from "./";

export type ProofSurfaceFields = Partial<FieldProps<ProofSurface>>;

/**
 * Generated class for ProofSurface__c
 */
export class ProofSurface extends RestObject {
    @sField({ apiName: 'RevisionSurfaces__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevisionSurface }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'RevisionSurfaces__r', externalId: false })
    public revisionSurfaces?: ProofRevisionSurface[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Surface Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'ProofFormat__r', createable: false, updateable: false, required: false, reference: () => { return ProofFormat }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof Format', externalId: false })
    public proofFormat?: ProofFormat;
    @sField({ apiName: 'ProofFormat__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof Format', externalId: false })
    public proofFormatId?: string | null;
    @sField({ apiName: 'Id__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'ID', externalId: true })
    public formatSurfaceId?: number | null;
    @sField({ apiName: 'EnumName__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Enum Name', externalId: false })
    public enumName?: string | null;
    @sField({ apiName: 'Index__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Index', externalId: false })
    public index?: number | null;
    /**
     * Radius of the rounded corners on the mask layer in pt. This is 0 for non-rounded corners.
     */
    @sField({ apiName: 'CornerRadius__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Corner Radius', externalId: false })
    public cornerRadius?: number | null;
    /**
     * Bleed in pt around each side of the artboard
     */
    @sField({ apiName: 'DesignBleed__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Design Bleed', externalId: false })
    public designBleed?: number | null;
    /**
     * Length of the longest side in pt
     */
    @sField({ apiName: 'LongDimension__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Long Dimension', externalId: false })
    public longDimension?: number | null;
    /**
     * Thickness of the mask on each side in pt
     */
    @sField({ apiName: 'MaskBleed__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Mask Bleed', externalId: false })
    public maskBleed?: number | null;
    /**
     * Length of the shortest dimension in pt
     */
    @sField({ apiName: 'ShortDimension__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Short Dimension', externalId: false })
    public shortDimension?: number | null;
    /**
     * Area of this surface in square inches
     */
    @sField({ apiName: 'AreaInches__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Area Inches', externalId: false })
    public readonly areaInches?: number | null;

    constructor(fields?: ProofSurfaceFields, restInstance?: Rest) {
        super('ProofSurface__c', restInstance);
        this.revisionSurfaces = void 0;
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.proofFormat = void 0;
        this.proofFormatId = void 0;
        this.formatSurfaceId = void 0;
        this.enumName = void 0;
        this.index = void 0;
        this.cornerRadius = void 0;
        this.designBleed = void 0;
        this.longDimension = void 0;
        this.maskBleed = void 0;
        this.shortDimension = void 0;
        this.areaInches = void 0;
        this.__UUID = ProofSurface.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofSurface__c' = 'ProofSurface__c';
    public readonly _TYPE_: 'ProofSurface__c' = 'ProofSurface__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofSurface>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofSurface.getPropertiesMeta<FieldProps<ProofSurface>, ProofSurface>(ProofSurface)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofSurface>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofSurface[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofSurface, qryParam) : qryParam;
        return await RestObject.query<ProofSurface>(ProofSurface, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofSurface {
        return new ProofSurface().mapFromQuery(sob);
    }
}
