import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, Contact } from "./";

export type DelightedResponseFields = Partial<FieldProps<DelightedResponse>>;

/**
 * Generated class for DelightedInc__Delighted_Response__c
 */
export class DelightedResponse extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Date', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'DelightedInc__Contact__r', createable: false, updateable: false, required: false, reference: () => { return Contact }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Contact', externalId: false })
    public contact?: Contact;
    @sField({ apiName: 'DelightedInc__Contact__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Contact', externalId: false })
    public contactId?: string | null;
    @sField({ apiName: 'DelightedInc__Comment__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Comment', externalId: false })
    public comment?: string | null;
    @sField({ apiName: 'DelightedInc__Delighted_ID__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Delighted ID', externalId: true })
    public delightedId?: string | null;
    @sField({ apiName: 'DelightedInc__Permalink__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Permalink', externalId: false })
    public permalink?: string | null;
    @sField({ apiName: 'DelightedInc__Properties__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Properties', externalId: false })
    public properties?: string | null;
    @sField({ apiName: 'DelightedInc__Score__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Score', externalId: false })
    public score?: number | null;
    @sField({ apiName: 'DelightedInc__Timestamp__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Timestamp', externalId: false })
    public timestamp?: Date | null;

    constructor(fields?: DelightedResponseFields, restInstance?: Rest) {
        super('DelightedInc__Delighted_Response__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.contact = void 0;
        this.contactId = void 0;
        this.comment = void 0;
        this.delightedId = void 0;
        this.permalink = void 0;
        this.properties = void 0;
        this.score = void 0;
        this.timestamp = void 0;
        this.__UUID = DelightedResponse.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'DelightedInc__Delighted_Response__c' = 'DelightedInc__Delighted_Response__c';
    public readonly _TYPE_: 'DelightedInc__Delighted_Response__c' = 'DelightedInc__Delighted_Response__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<DelightedResponse>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : DelightedResponse.getPropertiesMeta<FieldProps<DelightedResponse>, DelightedResponse>(DelightedResponse)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<DelightedResponse>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<DelightedResponse[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(DelightedResponse, qryParam) : qryParam;
        return await RestObject.query<DelightedResponse>(DelightedResponse, qry, opts);

    }

    public static fromSFObject(sob: SObject): DelightedResponse {
        return new DelightedResponse().mapFromQuery(sob);
    }
}
