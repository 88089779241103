// Adapted from https://stackoverflow.com/a/58257502/1058558
export const snakeKeysToCamelCase: (obj: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => { [key: string]: any } = (obj: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, val]) => [
      key.replace(/_(.)/g, (g) => g[1].toUpperCase()),
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      processVal(val)
    ])
  )

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processVal = (val: any): any =>
  typeof val !== 'object' || val === null
    ? val
    : Array.isArray(val)
    ? val.map(processVal)
    : snakeKeysToCamelCase(val)
