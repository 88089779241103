import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { Product2, User } from "./";

export type CategoryFields = Partial<FieldProps<Category>>;

/**
 * Generated class for Category__c
 */
export class Category extends RestObject {
    @sField({ apiName: 'Products__r', createable: false, updateable: false, required: false, reference: () => { return Product2 }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Products__r', externalId: false })
    public products?: Product2[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'OwnerId', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public ownerId?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Category Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    /**
     * Primary key of the Avetti category in the MySQL cards.categories table
     */
    @sField({ apiName: 'Id__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Id', externalId: true })
    public categoryId?: number | null;
    @sField({ apiName: 'MajorCategory__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Major Category', externalId: false })
    public majorCategory?: string | null;
    @sField({ apiName: 'MinorCategory__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Minor Category', externalId: false })
    public minorCategory?: string | null;
    @sField({ apiName: 'Url__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'URL', externalId: false })
    public url?: string | null;

    constructor(fields?: CategoryFields, restInstance?: Rest) {
        super('Category__c', restInstance);
        this.products = void 0;
        this.id = void 0;
        this.ownerId = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.categoryId = void 0;
        this.majorCategory = void 0;
        this.minorCategory = void 0;
        this.url = void 0;
        this.__UUID = Category.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Category__c' = 'Category__c';
    public readonly _TYPE_: 'Category__c' = 'Category__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Category>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Category.getPropertiesMeta<FieldProps<Category>, Category>(Category)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Category>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Category[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Category, qryParam) : qryParam;
        return await RestObject.query<Category>(Category, qry, opts);

    }

    public static fromSFObject(sob: SObject): Category {
        return new Category().mapFromQuery(sob);
    }
}
