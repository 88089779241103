import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { Discount, Negative, OrderAdjustment, OrderItem, OrderSpecialInstruction, Shipment, RecordType, User, Address, Account } from "./";

export type OrderFields = Partial<FieldProps<Order>>;

/**
 * Generated class for Order__c
 */
export class Order extends RestObject {
    @sField({ apiName: 'Discounts__r', createable: false, updateable: false, required: false, reference: () => { return Discount }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Discounts__r', externalId: false })
    public discounts?: Discount[];
    @sField({ apiName: 'Negatives__r', createable: false, updateable: false, required: false, reference: () => { return Negative }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Negatives__r', externalId: false })
    public negatives?: Negative[];
    @sField({ apiName: 'OrderAdjustments__r', createable: false, updateable: false, required: false, reference: () => { return OrderAdjustment }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'OrderAdjustments__r', externalId: false })
    public orderAdjustments?: OrderAdjustment[];
    @sField({ apiName: 'OrderItems__r', createable: false, updateable: false, required: false, reference: () => { return OrderItem }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'OrderItems__r', externalId: false })
    public orderItems?: OrderItem[];
    @sField({ apiName: 'OrderSpecialInstructions__r', createable: false, updateable: false, required: false, reference: () => { return OrderSpecialInstruction }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'OrderSpecialInstructions__r', externalId: false })
    public orderSpecialInstructions?: OrderSpecialInstruction[];
    @sField({ apiName: 'Shipments__r', createable: false, updateable: false, required: false, reference: () => { return Shipment }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Shipments__r', externalId: false })
    public shipments?: Shipment[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Order Number', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'RecordType', createable: false, updateable: false, required: false, reference: () => { return RecordType }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Record Type ID', externalId: false })
    public recordType?: RecordType;
    @sField({ apiName: 'RecordTypeId', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Record Type ID', externalId: false })
    public recordTypeId?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'OrderTime__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Order Time', externalId: false })
    public orderTime?: Date | null;
    @sField({ apiName: 'ShipDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Ship Date', externalId: false })
    public shipDate?: CalendarDate | null;
    @sField({ apiName: 'BillingAddress__r', createable: false, updateable: false, required: false, reference: () => { return Address }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Billing Address', externalId: false })
    public billingAddress?: Address;
    @sField({ apiName: 'BillingAddress__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Billing Address', externalId: false })
    public billingAddressId?: string | null;
    @sField({ apiName: 'CreditCardType__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Credit Card Type', externalId: false })
    public creditCardType?: string | null;
    @sField({ apiName: 'CreditCardLast4__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Credit Card Last 4', externalId: false })
    public creditCardLast4?: string | null;
    /**
     * Return address printed on Mail & Message envelopes
     */
    @sField({ apiName: 'ReturnAddress__r', createable: false, updateable: false, required: false, reference: () => { return Address }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Return Address', externalId: false })
    public returnAddress?: Address;
    /**
     * Return address printed on Mail & Message envelopes
     */
    @sField({ apiName: 'ReturnAddress__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Return Address', externalId: false })
    public returnAddressId?: string | null;
    /**
     * Send to Self shipping address
     */
    @sField({ apiName: 'ShippingAddress__r', createable: false, updateable: false, required: false, reference: () => { return Address }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Shipping Address', externalId: false })
    public shippingAddress?: Address;
    /**
     * Send to Self shipping address
     */
    @sField({ apiName: 'ShippingAddress__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Shipping Address', externalId: false })
    public shippingAddressId?: string | null;
    @sField({ apiName: 'UsPostage__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'US Postage', externalId: false })
    public usPostage?: number | null;
    @sField({ apiName: 'InternationalPostage__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'International Postage', externalId: false })
    public internationalPostage?: number | null;
    @sField({ apiName: 'InternationalPostageQuantity__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'International Postage Quantity', externalId: false })
    public internationalPostageQuantity?: number | null;
    @sField({ apiName: 'UsPostageQuantity__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'US Postage Quantity', externalId: false })
    public usPostageQuantity?: number | null;
    /**
     * Tax is calculated for all Shipped To You and Mailed For You sent to California
     */
    @sField({ apiName: 'Tax__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Tax', externalId: false })
    public tax?: number | null;
    /**
     * Cost of shipping + postage (if applicable). If there are discounts, the amount here will be offset by the discount field.
     */
    @sField({ apiName: 'Shipping__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Shipping', externalId: false })
    public shipping?: number | null;
    @sField({ apiName: 'Discount__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Discount', externalId: false })
    public discount?: number | null;
    @sField({ apiName: 'Total__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Total', externalId: false })
    public total?: number | null;
    @sField({ apiName: 'Subtotal__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Subtotal', externalId: false })
    public subtotal?: number | null;
    @sField({ apiName: 'Courier__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Courier', externalId: false })
    public courier?: string | null;
    @sField({ apiName: 'ShippingType__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Shipping Type', externalId: false })
    public shippingType?: string | null;
    @sField({ apiName: 'Status__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Status', externalId: false })
    public status?: string | null;
    @sField({ apiName: 'Customer__r', createable: false, updateable: false, required: false, reference: () => { return Account }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customer?: Account;
    @sField({ apiName: 'Customer__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customerId?: string | null;
    @sField({ apiName: 'CalculatedTotal__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Calculated Total', externalId: false })
    public readonly calculatedTotal?: number | null;
    @sField({ apiName: 'GiftMessage__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Gift Message', externalId: false })
    public giftMessage?: string | null;
    @sField({ apiName: 'IsReadyForPrint__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is Ready For Print', externalId: false })
    public readonly isReadyForPrint?: string | null;
    @sField({ apiName: 'OrderDataIdHostname__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Order Data ID + Hostname', externalId: true })
    public orderDataIdHostname?: string | null;
    @sField({ apiName: 'OrderDataId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Order Data ID', externalId: true })
    public orderDataId?: number | null;
    @sField({ apiName: 'OrderNumber__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Order Number', externalId: true })
    public orderNumber?: string | null;
    @sField({ apiName: 'PhoneNumber__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PHONE, salesforceLabel: 'Phone Number', externalId: false })
    public phoneNumber?: string | null;
    @sField({ apiName: 'CalculatedDiscounts__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Calculated Discounts', externalId: false })
    public readonly calculatedDiscounts?: number | null;
    /**
     * Number of valid items in the order (that aren't cancelled)
     */
    @sField({ apiName: 'ItemCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# Items', externalId: false })
    public readonly itemCount?: number | null;
    @sField({ apiName: 'MailAndMessageQuantity__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Mailed For You Quantity', externalId: false })
    public readonly mailAndMessageQuantity?: number | null;
    @sField({ apiName: 'MissingItemCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# Missing', externalId: false })
    public readonly missingItemCount?: number | null;
    @sField({ apiName: 'ReadyForPrintCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# Ready', externalId: false })
    public readonly readyForPrintCount?: number | null;
    @sField({ apiName: 'SendToSelfQuantity__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Send To Self Quantity', externalId: false })
    public readonly sendToSelfQuantity?: number | null;
    @sField({ apiName: 'ScheduledMailDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Scheduled Mail Date', externalId: false })
    public scheduledMailDate?: CalendarDate | null;
    @sField({ apiName: 'PrintedCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# Printed', externalId: false })
    public readonly printedCount?: number | null;
    @sField({ apiName: 'ShippedCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# Shipped', externalId: false })
    public readonly shippedCount?: number | null;
    /**
     * # of days since the order was placed
     */
    @sField({ apiName: 'DaysOld__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days Old', externalId: false })
    public readonly daysOld?: number | null;
    @sField({ apiName: 'TotalQuantity__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Total Quantity', externalId: false })
    public readonly totalQuantity?: number | null;
    @sField({ apiName: 'NotShippedCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# Not Shipped', externalId: false })
    public readonly notShippedCount?: number | null;
    @sField({ apiName: 'CalculatedMMQuantityEqualsCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'CalculatedMMQuantityEqualsCount', externalId: false })
    public readonly calculatedMmQuantityEqualsCount?: string | null;
    @sField({ apiName: 'CalculatedMailAndMessageQuantity__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Calculated Mail And Message Quantity', externalId: false })
    public readonly calculatedMailAndMessageQuantity?: number | null;
    /**
     * Text on the marketing or thank you card.
     * - To restore the default message, make this field blank and save it.
     * - To keep leading whitespace, start with a ` (backtick, on the ~ key, not a single quote) which will be removed automatically before printing.
     */
    @sField({ apiName: 'MarketingText__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Marketing Text', externalId: false })
    public marketingText?: string | null;
    @sField({ apiName: 'AddressesLastModifiedTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Addresses Last Modified Time', externalId: false })
    public addressesLastModifiedTime?: Date | null;
    @sField({ apiName: 'DaysToShip__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days To Ship', externalId: false })
    public readonly daysToShip?: number | null;
    @sField({ apiName: 'HasDelayedMailDate__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Has Delayed Mail Date', externalId: false })
    public readonly hasDelayedMailDate?: string | null;
    /**
     * On by default. However, if we shouldn't send a marketing (thank you) card to the customer, uncheck this box.
     */
    @sField({ apiName: 'IncludeMarketingCard__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Include Marketing Card', externalId: false })
    public includeMarketingCard?: boolean | null;
    /**
     * Checked if we've charged the customer's credit card. We can manually check this to skip charging their card or if we've already charged them outside of the system.
     */
    @sField({ apiName: 'IsPaymentCaptured__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Payment Captured', externalId: false })
    public isPaymentCaptured?: boolean | null;
    @sField({ apiName: 'MarketingBackPdf__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Marketing Back PDF Override', externalId: false })
    public marketingBackPdf?: string | null;
    /**
     * Populate a URL here to override the default marketing front PDF. The thank you card template is the default.
     */
    @sField({ apiName: 'MarketingFrontPdf__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Marketing Front PDF Override', externalId: false })
    public marketingFrontPdf?: string | null;
    @sField({ apiName: 'PercentDiscount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PERCENT, salesforceLabel: '% Discount', externalId: false })
    public readonly percentDiscount?: number | null;
    /**
     * Only use this if we need the printer to manually look at this order. This may delay shipment.
     *
     * Make sure you are very specific in your comments. Put comments under the Proof instead of the Order when possible.
     */
    @sField({ apiName: 'SpecialInstructionsForPrinter__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Special Instructions For Printer', externalId: false })
    public specialInstructionsForPrinter?: string | null;
    @sField({ apiName: 'EditReturnAddress__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Edit Return Address', externalId: false })
    public readonly editReturnAddress?: string | null;
    @sField({ apiName: 'EditShippingAddress__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Edit Shipping Address', externalId: false })
    public readonly editShippingAddress?: string | null;
    @sField({ apiName: 'ShipmentVerification__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Shipment Verification', externalId: false })
    public shipmentVerification?: boolean | null;
    @sField({ apiName: 'ShippedQuantity__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Shipped Quantity', externalId: false })
    public readonly shippedQuantity?: number | null;
    @sField({ apiName: 'LastBatchJobTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Batch Job Time', externalId: false })
    public lastBatchJobTime?: Date | null;
    /**
     * Days between Salesforce account creation (newsletter signup or login creation) and order creation
     */
    @sField({ apiName: 'DaysAfterEmailCollection__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days After Email Collection', externalId: false })
    public daysAfterEmailCollection?: number | null;
    /**
     * Notes to coordinate about the current status of the order. Usually used to share info about orders with problems or ones in danger of being late.
     */
    @sField({ apiName: 'ProcessingNotes__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Processing Notes', externalId: false })
    public processingNotes?: string | null;
    @sField({ apiName: 'OrderCounter__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Order Counter', externalId: false })
    public readonly orderCounter?: number | null;

    constructor(fields?: OrderFields, restInstance?: Rest) {
        super('Order__c', restInstance);
        this.discounts = void 0;
        this.negatives = void 0;
        this.orderAdjustments = void 0;
        this.orderItems = void 0;
        this.orderSpecialInstructions = void 0;
        this.shipments = void 0;
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.recordType = void 0;
        this.recordTypeId = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.orderTime = void 0;
        this.shipDate = void 0;
        this.billingAddress = void 0;
        this.billingAddressId = void 0;
        this.creditCardType = void 0;
        this.creditCardLast4 = void 0;
        this.returnAddress = void 0;
        this.returnAddressId = void 0;
        this.shippingAddress = void 0;
        this.shippingAddressId = void 0;
        this.usPostage = void 0;
        this.internationalPostage = void 0;
        this.internationalPostageQuantity = void 0;
        this.usPostageQuantity = void 0;
        this.tax = void 0;
        this.shipping = void 0;
        this.discount = void 0;
        this.total = void 0;
        this.subtotal = void 0;
        this.courier = void 0;
        this.shippingType = void 0;
        this.status = void 0;
        this.customer = void 0;
        this.customerId = void 0;
        this.calculatedTotal = void 0;
        this.giftMessage = void 0;
        this.isReadyForPrint = void 0;
        this.orderDataIdHostname = void 0;
        this.orderDataId = void 0;
        this.orderNumber = void 0;
        this.phoneNumber = void 0;
        this.calculatedDiscounts = void 0;
        this.itemCount = void 0;
        this.mailAndMessageQuantity = void 0;
        this.missingItemCount = void 0;
        this.readyForPrintCount = void 0;
        this.sendToSelfQuantity = void 0;
        this.scheduledMailDate = void 0;
        this.printedCount = void 0;
        this.shippedCount = void 0;
        this.daysOld = void 0;
        this.totalQuantity = void 0;
        this.notShippedCount = void 0;
        this.calculatedMmQuantityEqualsCount = void 0;
        this.calculatedMailAndMessageQuantity = void 0;
        this.marketingText = void 0;
        this.addressesLastModifiedTime = void 0;
        this.daysToShip = void 0;
        this.hasDelayedMailDate = void 0;
        this.includeMarketingCard = void 0;
        this.isPaymentCaptured = void 0;
        this.marketingBackPdf = void 0;
        this.marketingFrontPdf = void 0;
        this.percentDiscount = void 0;
        this.specialInstructionsForPrinter = void 0;
        this.editReturnAddress = void 0;
        this.editShippingAddress = void 0;
        this.shipmentVerification = void 0;
        this.shippedQuantity = void 0;
        this.lastBatchJobTime = void 0;
        this.daysAfterEmailCollection = void 0;
        this.processingNotes = void 0;
        this.orderCounter = void 0;
        this.__UUID = Order.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Order__c' = 'Order__c';
    public readonly _TYPE_: 'Order__c' = 'Order__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Order>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Order.getPropertiesMeta<FieldProps<Order>, Order>(Order)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Order>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Order[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Order, qryParam) : qryParam;
        return await RestObject.query<Order>(Order, qry, opts);

    }

    public static fromSFObject(sob: SObject): Order {
        return new Order().mapFromQuery(sob);
    }
}
