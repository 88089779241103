import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { Contact, CustomerPromotion, CustomerSpecialInstruction, DelightedResponse, Negative, Order, Project, Proof, SampleRequest, User, RecordType } from "./";

export type AccountFields = Partial<FieldProps<Account>>;

/**
 * Generated class for Account
 */
export class Account extends RestObject {
    @sField({ apiName: 'Contacts', createable: false, updateable: false, required: false, reference: () => { return Contact }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Contacts', externalId: false })
    public contacts?: Contact[];
    @sField({ apiName: 'CustomerPromotions__r', createable: false, updateable: false, required: false, reference: () => { return CustomerPromotion }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'CustomerPromotions__r', externalId: false })
    public customerPromotions?: CustomerPromotion[];
    @sField({ apiName: 'CustomerSpecialInstructions__r', createable: false, updateable: false, required: false, reference: () => { return CustomerSpecialInstruction }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'CustomerSpecialInstructions__r', externalId: false })
    public customerSpecialInstructions?: CustomerSpecialInstruction[];
    @sField({ apiName: 'DelightedInc__Delighted_Responses__pr', createable: false, updateable: false, required: false, reference: () => { return DelightedResponse }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'DelightedInc__Delighted_Responses__pr', externalId: false })
    public delightedResponses?: DelightedResponse[];
    @sField({ apiName: 'Negatives__r', createable: false, updateable: false, required: false, reference: () => { return Negative }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Negatives__r', externalId: false })
    public negatives?: Negative[];
    @sField({ apiName: 'Orders__r', createable: false, updateable: false, required: false, reference: () => { return Order }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Orders__r', externalId: false })
    public orders?: Order[];
    @sField({ apiName: 'Projects__r', createable: false, updateable: false, required: false, reference: () => { return Project }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Projects__r', externalId: false })
    public projects?: Project[];
    @sField({ apiName: 'Proofs__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proofs__r', externalId: false })
    public proofs?: Proof[];
    @sField({ apiName: 'SampleRequests__pr', createable: false, updateable: false, required: false, reference: () => { return SampleRequest }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'SampleRequests__pr', externalId: false })
    public sampleRequests?: SampleRequest[];
    @sField({ apiName: 'Users', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Users', externalId: false })
    public users?: User[];
    @sField({ apiName: 'PersonUsers', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'PersonUsers', externalId: false })
    public personUsers?: User[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Account ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'MasterRecord', createable: false, updateable: false, required: false, reference: () => { return Account }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Master Record ID', externalId: false })
    public masterRecord?: Account;
    @sField({ apiName: 'MasterRecordId', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Master Record ID', externalId: false })
    public readonly masterRecordId?: string | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Account Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'LastName', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Last Name', externalId: false })
    public lastName?: string | null;
    @sField({ apiName: 'FirstName', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'First Name', externalId: false })
    public firstName?: string | null;
    @sField({ apiName: 'Salutation', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Salutation', externalId: false })
    public salutation?: string | null;
    @sField({ apiName: 'Type', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Account Type', externalId: false })
    public type?: string | null;
    @sField({ apiName: 'RecordType', createable: false, updateable: false, required: false, reference: () => { return RecordType }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Record Type ID', externalId: false })
    public recordType?: RecordType;
    @sField({ apiName: 'RecordTypeId', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Record Type ID', externalId: false })
    public recordTypeId?: string | null;
    @sField({ apiName: 'Parent', createable: false, updateable: false, required: false, reference: () => { return Account }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Parent Account ID', externalId: false })
    public parent?: Account;
    @sField({ apiName: 'ParentId', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Parent Account ID', externalId: false })
    public parentId?: string | null;
    @sField({ apiName: 'BillingStreet', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Billing Street', externalId: false })
    public billingStreet?: string | null;
    @sField({ apiName: 'BillingCity', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Billing City', externalId: false })
    public billingCity?: string | null;
    @sField({ apiName: 'BillingState', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Billing State/Province', externalId: false })
    public billingState?: string | null;
    @sField({ apiName: 'BillingPostalCode', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Billing Zip/Postal Code', externalId: false })
    public billingPostalCode?: string | null;
    @sField({ apiName: 'BillingCountry', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Billing Country', externalId: false })
    public billingCountry?: string | null;
    @sField({ apiName: 'BillingLatitude', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Billing Latitude', externalId: false })
    public billingLatitude?: number | null;
    @sField({ apiName: 'BillingLongitude', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Billing Longitude', externalId: false })
    public billingLongitude?: number | null;
    @sField({ apiName: 'BillingGeocodeAccuracy', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Billing Geocode Accuracy', externalId: false })
    public billingGeocodeAccuracy?: string | null;
    @sField({ apiName: 'BillingAddress', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ADDRESS, salesforceLabel: 'Billing Address', externalId: false })
    public readonly billingAddress?: string | null;
    @sField({ apiName: 'ShippingStreet', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Shipping Street', externalId: false })
    public shippingStreet?: string | null;
    @sField({ apiName: 'ShippingCity', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Shipping City', externalId: false })
    public shippingCity?: string | null;
    @sField({ apiName: 'ShippingState', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Shipping State/Province', externalId: false })
    public shippingState?: string | null;
    @sField({ apiName: 'ShippingPostalCode', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Shipping Zip/Postal Code', externalId: false })
    public shippingPostalCode?: string | null;
    @sField({ apiName: 'ShippingCountry', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Shipping Country', externalId: false })
    public shippingCountry?: string | null;
    @sField({ apiName: 'ShippingLatitude', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Shipping Latitude', externalId: false })
    public shippingLatitude?: number | null;
    @sField({ apiName: 'ShippingLongitude', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Shipping Longitude', externalId: false })
    public shippingLongitude?: number | null;
    @sField({ apiName: 'ShippingGeocodeAccuracy', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Shipping Geocode Accuracy', externalId: false })
    public shippingGeocodeAccuracy?: string | null;
    @sField({ apiName: 'ShippingAddress', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ADDRESS, salesforceLabel: 'Shipping Address', externalId: false })
    public readonly shippingAddress?: string | null;
    @sField({ apiName: 'Phone', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PHONE, salesforceLabel: 'Account Phone', externalId: false })
    public phone?: string | null;
    @sField({ apiName: 'Fax', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PHONE, salesforceLabel: 'Account Fax', externalId: false })
    public fax?: string | null;
    @sField({ apiName: 'AccountNumber', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Account Number', externalId: false })
    public accountNumber?: string | null;
    @sField({ apiName: 'Website', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Website', externalId: false })
    public website?: string | null;
    @sField({ apiName: 'PhotoUrl', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Photo URL', externalId: false })
    public readonly photoUrl?: string | null;
    @sField({ apiName: 'Sic', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'SIC Code', externalId: false })
    public sic?: string | null;
    @sField({ apiName: 'Industry', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Industry', externalId: false })
    public industry?: string | null;
    @sField({ apiName: 'AnnualRevenue', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Annual Revenue', externalId: false })
    public annualRevenue?: number | null;
    @sField({ apiName: 'NumberOfEmployees', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.INT, salesforceLabel: 'Employees', externalId: false })
    public numberOfEmployees?: number | null;
    @sField({ apiName: 'Ownership', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Ownership', externalId: false })
    public ownership?: string | null;
    @sField({ apiName: 'TickerSymbol', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Ticker Symbol', externalId: false })
    public tickerSymbol?: string | null;
    @sField({ apiName: 'Description', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Account Description', externalId: false })
    public description?: string | null;
    @sField({ apiName: 'Rating', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Account Rating', externalId: false })
    public rating?: string | null;
    @sField({ apiName: 'Site', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Account Site', externalId: false })
    public site?: string | null;
    @sField({ apiName: 'Owner', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public owner?: User;
    @sField({ apiName: 'OwnerId', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public ownerId?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'PersonContact', createable: false, updateable: false, required: false, reference: () => { return Contact }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Contact ID', externalId: false })
    public personContact?: Contact;
    @sField({ apiName: 'PersonContactId', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Contact ID', externalId: false })
    public readonly personContactId?: string | null;
    @sField({ apiName: 'IsPersonAccount', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Person Account', externalId: false })
    public readonly isPersonAccount?: boolean | null;
    @sField({ apiName: 'PersonMailingStreet', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Mailing Street', externalId: false })
    public personMailingStreet?: string | null;
    @sField({ apiName: 'PersonMailingCity', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Mailing City', externalId: false })
    public personMailingCity?: string | null;
    @sField({ apiName: 'PersonMailingState', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Mailing State/Province', externalId: false })
    public personMailingState?: string | null;
    @sField({ apiName: 'PersonMailingPostalCode', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Mailing Zip/Postal Code', externalId: false })
    public personMailingPostalCode?: string | null;
    @sField({ apiName: 'PersonMailingCountry', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Mailing Country', externalId: false })
    public personMailingCountry?: string | null;
    @sField({ apiName: 'PersonMailingLatitude', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Mailing Latitude', externalId: false })
    public personMailingLatitude?: number | null;
    @sField({ apiName: 'PersonMailingLongitude', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Mailing Longitude', externalId: false })
    public personMailingLongitude?: number | null;
    @sField({ apiName: 'PersonMailingGeocodeAccuracy', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Mailing Geocode Accuracy', externalId: false })
    public personMailingGeocodeAccuracy?: string | null;
    @sField({ apiName: 'PersonMailingAddress', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ADDRESS, salesforceLabel: 'Mailing Address', externalId: false })
    public readonly personMailingAddress?: string | null;
    @sField({ apiName: 'PersonOtherStreet', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Other Street', externalId: false })
    public personOtherStreet?: string | null;
    @sField({ apiName: 'PersonOtherCity', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Other City', externalId: false })
    public personOtherCity?: string | null;
    @sField({ apiName: 'PersonOtherState', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Other State/Province', externalId: false })
    public personOtherState?: string | null;
    @sField({ apiName: 'PersonOtherPostalCode', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Other Zip/Postal Code', externalId: false })
    public personOtherPostalCode?: string | null;
    @sField({ apiName: 'PersonOtherCountry', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Other Country', externalId: false })
    public personOtherCountry?: string | null;
    @sField({ apiName: 'PersonOtherLatitude', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Other Latitude', externalId: false })
    public personOtherLatitude?: number | null;
    @sField({ apiName: 'PersonOtherLongitude', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Other Longitude', externalId: false })
    public personOtherLongitude?: number | null;
    @sField({ apiName: 'PersonOtherGeocodeAccuracy', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Other Geocode Accuracy', externalId: false })
    public personOtherGeocodeAccuracy?: string | null;
    @sField({ apiName: 'PersonOtherAddress', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ADDRESS, salesforceLabel: 'Other Address', externalId: false })
    public readonly personOtherAddress?: string | null;
    @sField({ apiName: 'PersonMobilePhone', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PHONE, salesforceLabel: 'Mobile', externalId: false })
    public personMobilePhone?: string | null;
    @sField({ apiName: 'PersonHomePhone', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PHONE, salesforceLabel: 'Home Phone', externalId: false })
    public personHomePhone?: string | null;
    @sField({ apiName: 'PersonOtherPhone', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PHONE, salesforceLabel: 'Other Phone', externalId: false })
    public personOtherPhone?: string | null;
    @sField({ apiName: 'PersonAssistantPhone', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PHONE, salesforceLabel: 'Asst. Phone', externalId: false })
    public personAssistantPhone?: string | null;
    @sField({ apiName: 'PersonEmail', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.EMAIL, salesforceLabel: 'Email', externalId: false })
    public personEmail?: string | null;
    @sField({ apiName: 'PersonTitle', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Title', externalId: false })
    public personTitle?: string | null;
    @sField({ apiName: 'PersonDepartment', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Department', externalId: false })
    public personDepartment?: string | null;
    @sField({ apiName: 'PersonAssistantName', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Assistant', externalId: false })
    public personAssistantName?: string | null;
    @sField({ apiName: 'PersonLeadSource', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Lead Source', externalId: false })
    public personLeadSource?: string | null;
    @sField({ apiName: 'PersonBirthdate', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Birthdate', externalId: false })
    public personBirthdate?: CalendarDate | null;
    @sField({ apiName: 'PersonHasOptedOutOfEmail', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Email Opt Out', externalId: false })
    public personHasOptedOutOfEmail?: boolean | null;
    @sField({ apiName: 'PersonHasOptedOutOfFax', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Fax Opt Out', externalId: false })
    public personHasOptedOutOfFax?: boolean | null;
    @sField({ apiName: 'PersonDoNotCall', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Do Not Call', externalId: false })
    public personDoNotCall?: boolean | null;
    @sField({ apiName: 'PersonLastCURequestDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Stay-in-Touch Request Date', externalId: false })
    public readonly personLastCuRequestDate?: Date | null;
    @sField({ apiName: 'PersonLastCUUpdateDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Stay-in-Touch Save Date', externalId: false })
    public readonly personLastCuUpdateDate?: Date | null;
    @sField({ apiName: 'PersonEmailBouncedReason', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Email Bounced Reason', externalId: false })
    public personEmailBouncedReason?: string | null;
    @sField({ apiName: 'PersonEmailBouncedDate', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Email Bounced Date', externalId: false })
    public personEmailBouncedDate?: Date | null;
    @sField({ apiName: 'PersonIndividualId', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Individual ID', externalId: false })
    public personIndividualId?: string | null;
    @sField({ apiName: 'Jigsaw', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Data.com Key', externalId: false })
    public jigsaw?: string | null;
    @sField({ apiName: 'JigsawCompanyId', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Jigsaw Company ID', externalId: false })
    public readonly jigsawCompanyId?: string | null;
    @sField({ apiName: 'AccountSource', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Account Source', externalId: false })
    public accountSource?: string | null;
    @sField({ apiName: 'SicDesc', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'SIC Description', externalId: false })
    public sicDesc?: string | null;
    /**
     * An example Rollup Summary field that adds all of the account's won opportunities.
     */
    @sField({ apiName: 'Total_Won_Opportunities__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Total Won Opportunities', externalId: false })
    public readonly totalWonOpportunities?: number | null;
    @sField({ apiName: 'Google_Maps_Link__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Google Maps Link', externalId: false })
    public readonly googleMapsLink?: string | null;
    @sField({ apiName: 'ClientId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Client ID', externalId: true })
    public clientId?: number | null;
    @sField({ apiName: 'LoginName__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.EMAIL, salesforceLabel: 'Login Name', externalId: true })
    public loginName?: string | null;
    @sField({ apiName: 'RegistrationDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Registration Date', externalId: false })
    public registrationDate?: Date | null;
    @sField({ apiName: 'Vip__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'VIP', externalId: false })
    public vip?: boolean | null;
    @sField({ apiName: 'NegativeCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# Negatives', externalId: false })
    public readonly negativeCount?: number | null;
    @sField({ apiName: 'AmountSpent__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Amount Spent', externalId: false })
    public readonly amountSpent?: number | null;
    @sField({ apiName: 'OrderCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# Orders', externalId: false })
    public readonly orderCount?: number | null;
    /**
     * Custom field to segment accounts by satisfaction level
     */
    @sField({ apiName: 'Satisfaction_Level__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Satisfaction Level', externalId: false })
    public satisfactionLevel?: string | null;
    /**
     * Custom Formula Field based on satisfaction level
     */
    @sField({ apiName: 'Satisfaction_Indicator__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Satisfaction Indicator', externalId: false })
    public readonly satisfactionIndicator?: string | null;
    @sField({ apiName: 'AmountSpent2Days__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Amount Spent (2 Days)', externalId: false })
    public readonly amountSpent2Days?: number | null;
    @sField({ apiName: 'AmountSpent7Days__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Amount Spent (7 Days)', externalId: false })
    public readonly amountSpent7Days?: number | null;
    @sField({ apiName: 'AmountSpent14Days__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Amount Spent (14 Days)', externalId: false })
    public readonly amountSpent14Days?: number | null;
    @sField({ apiName: 'AmountSpent30Days__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Amount Spent (30 Days)', externalId: false })
    public readonly amountSpent30Days?: number | null;
    @sField({ apiName: 'SignupCustom1__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 1', externalId: false })
    public signupCustom1?: string | null;
    @sField({ apiName: 'SignupClass__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Bidding Class', externalId: false })
    public signupClass?: string | null;
    @sField({ apiName: 'SignupCreativeDescription__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Creative Description', externalId: false })
    public signupCreativeDescription?: string | null;
    @sField({ apiName: 'SignupCustom2__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 2', externalId: false })
    public signupCustom2?: string | null;
    @sField({ apiName: 'SignupCustom3__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 3', externalId: false })
    public signupCustom3?: string | null;
    @sField({ apiName: 'SignupCustom4__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 4', externalId: false })
    public signupCustom4?: string | null;
    @sField({ apiName: 'SignupCustom5__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 5', externalId: false })
    public signupCustom5?: string | null;
    @sField({ apiName: 'SignupCustom6__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 6', externalId: false })
    public signupCustom6?: string | null;
    @sField({ apiName: 'SignupCustom7__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 7', externalId: false })
    public signupCustom7?: string | null;
    @sField({ apiName: 'SignupCustom8__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 8', externalId: false })
    public signupCustom8?: string | null;
    @sField({ apiName: 'SignupCustom9__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Custom 9', externalId: false })
    public signupCustom9?: string | null;
    @sField({ apiName: 'SignupDestination__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Landing Destination', externalId: false })
    public signupDestination?: string | null;
    @sField({ apiName: 'SignupSegment__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Segment', externalId: false })
    public signupSegment?: string | null;
    @sField({ apiName: 'SignupUtmCampaign__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: UTM Campaign', externalId: false })
    public signupUtmCampaign?: string | null;
    @sField({ apiName: 'SignupUtmContent__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: UTM Content', externalId: false })
    public signupUtmContent?: string | null;
    @sField({ apiName: 'SignupUtmMedium__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: UTM Medium', externalId: false })
    public signupUtmMedium?: string | null;
    @sField({ apiName: 'SignupUtmSource__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: UTM Source', externalId: false })
    public signupUtmSource?: string | null;
    @sField({ apiName: 'SignupUtmTerm__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: UTM Term', externalId: false })
    public signupUtmTerm?: string | null;
    @sField({ apiName: 'EmailCollectionDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Email Collection Date', externalId: false })
    public emailCollectionDate?: Date | null;
    @sField({ apiName: 'SignupReferer__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Signup: Referer', externalId: false })
    public signupReferer?: string | null;
    @sField({ apiName: 'SignupBrowserVersion__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Browser Version', externalId: false })
    public signupBrowserVersion?: string | null;
    @sField({ apiName: 'SignupBrowser__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Browser', externalId: false })
    public signupBrowser?: string | null;
    @sField({ apiName: 'SignupDeviceType__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Device Type', externalId: false })
    public signupDeviceType?: string | null;
    @sField({ apiName: 'SignupOperatingSystemVersion__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Operating System Version', externalId: false })
    public signupOperatingSystemVersion?: string | null;
    @sField({ apiName: 'SignupOperatingSystem__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Operating System', externalId: false })
    public signupOperatingSystem?: string | null;
    @sField({ apiName: 'SignupRefererMedium__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Referer Medium', externalId: false })
    public signupRefererMedium?: string | null;
    @sField({ apiName: 'SignupRefererSearchTerm__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Referer Search Term', externalId: false })
    public signupRefererSearchTerm?: string | null;
    @sField({ apiName: 'SignupRefererSource__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Signup: Referer Source', externalId: false })
    public signupRefererSource?: string | null;
    @sField({ apiName: 'SignupUserAgent__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Signup: User Agent', externalId: false })
    public signupUserAgent?: string | null;
    @sField({ apiName: 'TimeZone__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Time Zone', externalId: false })
    public timeZone?: string | null;
    @sField({ apiName: 'MarketoTrackingCookie__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Marketo Tracking Cookie', externalId: false })
    public marketoTrackingCookie?: string | null;
    /**
     * Date and time we last triggered a Net Protomer Score survey to the customer
     */
    @sField({ apiName: 'LastNpsSurveySent__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last NPS Survey Sent', externalId: false })
    public lastNpsSurveySent?: Date | null;
    @sField({ apiName: 'AccountCounter__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Account Counter', externalId: false })
    public readonly accountCounter?: number | null;
    @sField({ apiName: 'DelightedInc__Last_Delighted_Response_Score__pc', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Last Delighted Response Score', externalId: false })
    public lastDelightedResponseScore?: number | null;
    @sField({ apiName: 'WeddingDate__pc', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Wedding Date', externalId: false })
    public weddingDate?: CalendarDate | null;
    @sField({ apiName: 'MarketoLeadId__pc', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Marketo Lead ID', externalId: false })
    public marketoLeadId?: number | null;
    @sField({ apiName: 'MarketoTrackingCookies__pc', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Marketo Tracking Cookies', externalId: false })
    public marketoTrackingCookies?: string | null;
    /**
     * Internal name that describes how we first got somebody's email. This can be used to explain how somebody got into our email database and why they're receiving our emails. There should be a finite and manageable set of values in this field.
     */
    @sField({ apiName: 'EmailCollectionSource__pc', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Email Collection Source', externalId: false })
    public emailCollectionSource?: string | null;
    @sField({ apiName: 'Email__pc', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.EMAIL, salesforceLabel: 'Email', externalId: false })
    public contactEmail?: string | null;
    @sField({ apiName: 'DeleteFromMarketo__pc', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Delete From Marketo', externalId: false })
    public deleteFromMarketo?: boolean | null;
    @sField({ apiName: 'DisableMarketoSync__pc', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Disable Marketo Sync', externalId: false })
    public disableMarketoSync?: boolean | null;

    constructor(fields?: AccountFields, restInstance?: Rest) {
        super('Account', restInstance);
        this.contacts = void 0;
        this.customerPromotions = void 0;
        this.customerSpecialInstructions = void 0;
        this.delightedResponses = void 0;
        this.negatives = void 0;
        this.orders = void 0;
        this.projects = void 0;
        this.proofs = void 0;
        this.sampleRequests = void 0;
        this.users = void 0;
        this.personUsers = void 0;
        this.id = void 0;
        this.isDeleted = void 0;
        this.masterRecord = void 0;
        this.masterRecordId = void 0;
        this.name = void 0;
        this.lastName = void 0;
        this.firstName = void 0;
        this.salutation = void 0;
        this.type = void 0;
        this.recordType = void 0;
        this.recordTypeId = void 0;
        this.parent = void 0;
        this.parentId = void 0;
        this.billingStreet = void 0;
        this.billingCity = void 0;
        this.billingState = void 0;
        this.billingPostalCode = void 0;
        this.billingCountry = void 0;
        this.billingLatitude = void 0;
        this.billingLongitude = void 0;
        this.billingGeocodeAccuracy = void 0;
        this.billingAddress = void 0;
        this.shippingStreet = void 0;
        this.shippingCity = void 0;
        this.shippingState = void 0;
        this.shippingPostalCode = void 0;
        this.shippingCountry = void 0;
        this.shippingLatitude = void 0;
        this.shippingLongitude = void 0;
        this.shippingGeocodeAccuracy = void 0;
        this.shippingAddress = void 0;
        this.phone = void 0;
        this.fax = void 0;
        this.accountNumber = void 0;
        this.website = void 0;
        this.photoUrl = void 0;
        this.sic = void 0;
        this.industry = void 0;
        this.annualRevenue = void 0;
        this.numberOfEmployees = void 0;
        this.ownership = void 0;
        this.tickerSymbol = void 0;
        this.description = void 0;
        this.rating = void 0;
        this.site = void 0;
        this.owner = void 0;
        this.ownerId = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.personContact = void 0;
        this.personContactId = void 0;
        this.isPersonAccount = void 0;
        this.personMailingStreet = void 0;
        this.personMailingCity = void 0;
        this.personMailingState = void 0;
        this.personMailingPostalCode = void 0;
        this.personMailingCountry = void 0;
        this.personMailingLatitude = void 0;
        this.personMailingLongitude = void 0;
        this.personMailingGeocodeAccuracy = void 0;
        this.personMailingAddress = void 0;
        this.personOtherStreet = void 0;
        this.personOtherCity = void 0;
        this.personOtherState = void 0;
        this.personOtherPostalCode = void 0;
        this.personOtherCountry = void 0;
        this.personOtherLatitude = void 0;
        this.personOtherLongitude = void 0;
        this.personOtherGeocodeAccuracy = void 0;
        this.personOtherAddress = void 0;
        this.personMobilePhone = void 0;
        this.personHomePhone = void 0;
        this.personOtherPhone = void 0;
        this.personAssistantPhone = void 0;
        this.personEmail = void 0;
        this.personTitle = void 0;
        this.personDepartment = void 0;
        this.personAssistantName = void 0;
        this.personLeadSource = void 0;
        this.personBirthdate = void 0;
        this.personHasOptedOutOfEmail = void 0;
        this.personHasOptedOutOfFax = void 0;
        this.personDoNotCall = void 0;
        this.personLastCuRequestDate = void 0;
        this.personLastCuUpdateDate = void 0;
        this.personEmailBouncedReason = void 0;
        this.personEmailBouncedDate = void 0;
        this.personIndividualId = void 0;
        this.jigsaw = void 0;
        this.jigsawCompanyId = void 0;
        this.accountSource = void 0;
        this.sicDesc = void 0;
        this.totalWonOpportunities = void 0;
        this.googleMapsLink = void 0;
        this.clientId = void 0;
        this.loginName = void 0;
        this.registrationDate = void 0;
        this.vip = void 0;
        this.negativeCount = void 0;
        this.amountSpent = void 0;
        this.orderCount = void 0;
        this.satisfactionLevel = void 0;
        this.satisfactionIndicator = void 0;
        this.amountSpent2Days = void 0;
        this.amountSpent7Days = void 0;
        this.amountSpent14Days = void 0;
        this.amountSpent30Days = void 0;
        this.signupCustom1 = void 0;
        this.signupClass = void 0;
        this.signupCreativeDescription = void 0;
        this.signupCustom2 = void 0;
        this.signupCustom3 = void 0;
        this.signupCustom4 = void 0;
        this.signupCustom5 = void 0;
        this.signupCustom6 = void 0;
        this.signupCustom7 = void 0;
        this.signupCustom8 = void 0;
        this.signupCustom9 = void 0;
        this.signupDestination = void 0;
        this.signupSegment = void 0;
        this.signupUtmCampaign = void 0;
        this.signupUtmContent = void 0;
        this.signupUtmMedium = void 0;
        this.signupUtmSource = void 0;
        this.signupUtmTerm = void 0;
        this.emailCollectionDate = void 0;
        this.signupReferer = void 0;
        this.signupBrowserVersion = void 0;
        this.signupBrowser = void 0;
        this.signupDeviceType = void 0;
        this.signupOperatingSystemVersion = void 0;
        this.signupOperatingSystem = void 0;
        this.signupRefererMedium = void 0;
        this.signupRefererSearchTerm = void 0;
        this.signupRefererSource = void 0;
        this.signupUserAgent = void 0;
        this.timeZone = void 0;
        this.marketoTrackingCookie = void 0;
        this.lastNpsSurveySent = void 0;
        this.accountCounter = void 0;
        this.lastDelightedResponseScore = void 0;
        this.weddingDate = void 0;
        this.marketoLeadId = void 0;
        this.marketoTrackingCookies = void 0;
        this.emailCollectionSource = void 0;
        this.contactEmail = void 0;
        this.deleteFromMarketo = void 0;
        this.disableMarketoSync = void 0;
        this.__UUID = Account.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Account' = 'Account';
    public readonly _TYPE_: 'Account' = 'Account';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Account>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Account.getPropertiesMeta<FieldProps<Account>, Account>(Account)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Account>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Account[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Account, qryParam) : qryParam;
        return await RestObject.query<Account>(Account, qry, opts);

    }

    public static fromSFObject(sob: SObject): Account {
        return new Account().mapFromQuery(sob);
    }
}
