import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { ProofPhoto, ProofRevisionSurfaceOverlay, User, ProofSurface, ProofRevision } from "./";

export type ProofRevisionSurfaceFields = Partial<FieldProps<ProofRevisionSurface>>;

/**
 * Generated class for ProofRevisionSurface__c
 */
export class ProofRevisionSurface extends RestObject {
    @sField({ apiName: 'Photos__r', createable: false, updateable: false, required: false, reference: () => { return ProofPhoto }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Photos__r', externalId: false })
    public photos?: ProofPhoto[];
    @sField({ apiName: 'RevisionSurfaceOverlays__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevisionSurfaceOverlay }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'RevisionSurfaceOverlays__r', externalId: false })
    public revisionSurfaceOverlays?: ProofRevisionSurfaceOverlay[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Revision Surface Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'Surface__r', createable: false, updateable: false, required: false, reference: () => { return ProofSurface }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Surface', externalId: false })
    public surface?: ProofSurface;
    @sField({ apiName: 'Surface__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Surface', externalId: false })
    public surfaceId?: string | null;
    @sField({ apiName: 'ProofUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Proof URL', externalId: false })
    public proofUrl?: string | null;
    @sField({ apiName: 'Hidden__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Hidden', externalId: false })
    public hidden?: boolean | null;
    @sField({ apiName: 'Id__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'ID', externalId: true })
    public proofRevisionSurfaceId?: string | null;
    @sField({ apiName: 'PdfUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'PDF URL', externalId: false })
    public pdfUrl?: string | null;
    @sField({ apiName: 'Revision__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevision }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision', externalId: false })
    public revision?: ProofRevision;
    @sField({ apiName: 'Revision__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision', externalId: false })
    public revisionId?: string | null;
    @sField({ apiName: 'RawProofUrlHeight__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Raw Proof URL Height', externalId: false })
    public rawProofUrlHeight?: number | null;
    @sField({ apiName: 'RawProofUrlWidth__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Raw Proof URL Width', externalId: false })
    public rawProofUrlWidth?: number | null;
    @sField({ apiName: 'RawProofUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Raw Proof URL', externalId: false })
    public rawProofUrl?: string | null;
    @sField({ apiName: 'LowResolutionPhotoCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# of Low Resolution Photos', externalId: false })
    public readonly lowResolutionPhotoCount?: number | null;
    @sField({ apiName: 'PhotoCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Total # of Photos', externalId: false })
    public readonly photoCount?: number | null;
    @sField({ apiName: 'Orientation__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Orientation', externalId: false })
    public readonly orientation?: string | null;
    @sField({ apiName: 'Image__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Image', externalId: false })
    public readonly image?: string | null;
    @sField({ apiName: 'LargeImage__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Large Image', externalId: false })
    public readonly largeImage?: string | null;

    constructor(fields?: ProofRevisionSurfaceFields, restInstance?: Rest) {
        super('ProofRevisionSurface__c', restInstance);
        this.photos = void 0;
        this.revisionSurfaceOverlays = void 0;
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.surface = void 0;
        this.surfaceId = void 0;
        this.proofUrl = void 0;
        this.hidden = void 0;
        this.proofRevisionSurfaceId = void 0;
        this.pdfUrl = void 0;
        this.revision = void 0;
        this.revisionId = void 0;
        this.rawProofUrlHeight = void 0;
        this.rawProofUrlWidth = void 0;
        this.rawProofUrl = void 0;
        this.lowResolutionPhotoCount = void 0;
        this.photoCount = void 0;
        this.orientation = void 0;
        this.image = void 0;
        this.largeImage = void 0;
        this.__UUID = ProofRevisionSurface.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofRevisionSurface__c' = 'ProofRevisionSurface__c';
    public readonly _TYPE_: 'ProofRevisionSurface__c' = 'ProofRevisionSurface__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofRevisionSurface>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofRevisionSurface.getPropertiesMeta<FieldProps<ProofRevisionSurface>, ProofRevisionSurface>(ProofRevisionSurface)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofRevisionSurface>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofRevisionSurface[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofRevisionSurface, qryParam) : qryParam;
        return await RestObject.query<ProofRevisionSurface>(ProofRevisionSurface, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofRevisionSurface {
        return new ProofRevisionSurface().mapFromQuery(sob);
    }
}
