import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, Account, Order } from "./";

export type NegativeFields = Partial<FieldProps<Negative>>;

/**
 * Generated class for Negative__c
 */
export class Negative extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Summary', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'Account__r', createable: false, updateable: false, required: false, reference: () => { return Account }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Account', externalId: false })
    public account?: Account;
    @sField({ apiName: 'Account__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Account', externalId: false })
    public accountId?: string | null;
    @sField({ apiName: 'Category__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.MULTIPICKLIST, salesforceLabel: 'Category', externalId: false })
    public category?: string[] | null;
    /**
     * Optional detailed information about the problem
     */
    @sField({ apiName: 'Description__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Additional Description', externalId: false })
    public description?: string | null;
    /**
     * 1 is low severity, 5 is high.
     * 1: Didn't like the envelopes, thought it would ship sooner but within promised window
     * 2: Small spot on 1-2 cards
     * 3: Shipped late
     * 4: Systematic printing issue with cards
     * 5: Sent the wrong person's cards
     */
    @sField({ apiName: 'Severity__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Severity (1 lo, 5 hi)', externalId: false })
    public severity?: string | null;
    /**
     * select all that apply
     */
    @sField({ apiName: 'NegativeResponsibility__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.MULTIPICKLIST, salesforceLabel: 'Negative Responsibility', externalId: false })
    public negativeResponsibility?: string[] | null;
    /**
     * Check if customer was given a credit for a future order
     */
    @sField({ apiName: 'CreditGiven__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Credit Given', externalId: false })
    public creditGiven?: boolean | null;
    /**
     * Check the box if customer was provided a refund
     */
    @sField({ apiName: 'RefundGiven__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Refund Given', externalId: false })
    public refundGiven?: boolean | null;
    /**
     * Check the box if the customer was provided a complimentary shipping upgrade
     */
    @sField({ apiName: 'ShippingUpgrade__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Shipping Upgrade', externalId: false })
    public shippingUpgrade?: boolean | null;
    /**
     * Check if we provided a reprint
     */
    @sField({ apiName: 'Reprinted__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Reprinted', externalId: false })
    public reprinted?: boolean | null;
    /**
     * What did we do to help resolve the problem?
     */
    @sField({ apiName: 'ResolutionDescription__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Resolution Description', externalId: false })
    public resolutionDescription?: string | null;
    @sField({ apiName: 'Order__r', createable: false, updateable: false, required: false, reference: () => { return Order }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order Number', externalId: false })
    public order?: Order;
    @sField({ apiName: 'Order__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order Number', externalId: false })
    public orderId?: string | null;
    /**
     * Link to the URL of the Zendesk ticket that reflects the negative
     */
    @sField({ apiName: 'ZendeskTicketUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Zendesk Ticket URL', externalId: false })
    public zendeskTicketUrl?: string | null;

    constructor(fields?: NegativeFields, restInstance?: Rest) {
        super('Negative__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.account = void 0;
        this.accountId = void 0;
        this.category = void 0;
        this.description = void 0;
        this.severity = void 0;
        this.negativeResponsibility = void 0;
        this.creditGiven = void 0;
        this.refundGiven = void 0;
        this.shippingUpgrade = void 0;
        this.reprinted = void 0;
        this.resolutionDescription = void 0;
        this.order = void 0;
        this.orderId = void 0;
        this.zendeskTicketUrl = void 0;
        this.__UUID = Negative.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Negative__c' = 'Negative__c';
    public readonly _TYPE_: 'Negative__c' = 'Negative__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Negative>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Negative.getPropertiesMeta<FieldProps<Negative>, Negative>(Negative)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Negative>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Negative[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Negative, qryParam) : qryParam;
        return await RestObject.query<Negative>(Negative, qry, opts);

    }

    public static fromSFObject(sob: SObject): Negative {
        return new Negative().mapFromQuery(sob);
    }
}
