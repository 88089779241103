import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User } from "./";

export type RecordTypeFields = Partial<FieldProps<RecordType>>;

/**
 * Generated class for RecordType
 */
export class RecordType extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record Type ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'DeveloperName', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Record Type Name', externalId: false })
    public developerName?: string | null;
    @sField({ apiName: 'NamespacePrefix', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Namespace Prefix', externalId: false })
    public readonly namespacePrefix?: string | null;
    @sField({ apiName: 'Description', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Description', externalId: false })
    public description?: string | null;
    @sField({ apiName: 'BusinessProcessId', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Business Process ID', externalId: false })
    public businessProcessId?: string | null;
    @sField({ apiName: 'SobjectType', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'SObject Type Name', externalId: false })
    public sobjectType?: string | null;
    @sField({ apiName: 'IsActive', createable: false, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Active', externalId: false })
    public isActive?: boolean | null;
    @sField({ apiName: 'IsPersonType', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Person Type', externalId: false })
    public readonly isPersonType?: boolean | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;

    constructor(fields?: RecordTypeFields, restInstance?: Rest) {
        super('RecordType', restInstance);
        this.id = void 0;
        this.name = void 0;
        this.developerName = void 0;
        this.namespacePrefix = void 0;
        this.description = void 0;
        this.businessProcessId = void 0;
        this.sobjectType = void 0;
        this.isActive = void 0;
        this.isPersonType = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.createdDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.lastModifiedDate = void 0;
        this.systemModstamp = void 0;
        this.__UUID = RecordType.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'RecordType' = 'RecordType';
    public readonly _TYPE_: 'RecordType' = 'RecordType';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<RecordType>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : RecordType.getPropertiesMeta<FieldProps<RecordType>, RecordType>(RecordType)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<RecordType>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<RecordType[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(RecordType, qryParam) : qryParam;
        return await RestObject.query<RecordType>(RecordType, qry, opts);

    }

    public static fromSFObject(sob: SObject): RecordType {
        return new RecordType().mapFromQuery(sob);
    }
}
