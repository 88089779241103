import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { Proof, ShipmentOrderItem, User, Order, Project, Shipment, Product2 } from "./";

export type OrderItemFields = Partial<FieldProps<OrderItem>>;

/**
 * Generated class for OrderItem__c
 */
export class OrderItem extends RestObject {
    @sField({ apiName: 'Proofs__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proofs__r', externalId: false })
    public proofs?: Proof[];
    @sField({ apiName: 'ShipmentOrderItems__r', createable: false, updateable: false, required: false, reference: () => { return ShipmentOrderItem }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'ShipmentOrderItems__r', externalId: false })
    public shipmentOrderItems?: ShipmentOrderItem[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Order Item Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'Order__r', createable: false, updateable: false, required: false, reference: () => { return Order }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order', externalId: false })
    public order?: Order;
    @sField({ apiName: 'Order__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order', externalId: false })
    public orderId?: string | null;
    @sField({ apiName: 'Code__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Code', externalId: false })
    public code?: string | null;
    @sField({ apiName: 'Comment__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Comment', externalId: false })
    public comment?: string | null;
    @sField({ apiName: 'Cost__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Cost', externalId: false })
    public cost?: number | null;
    /**
     * Changing this allows you to change the delivery type of an existing item. The names here are legacy names. Send to Self is now Shipped To You, Address & Message is Shipped To You w/ Printed Recipient Address, Mail & Message is Mailed For You.
     */
    @sField({ apiName: 'DeliveryType__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Delivery Type (Old Name)', externalId: false })
    public deliveryType?: string | null;
    @sField({ apiName: 'ExistsInSalesforce__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Exists In Salesforce', externalId: false })
    public existsInSalesforce?: boolean | null;
    @sField({ apiName: 'ItemId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Item ID', externalId: false })
    public itemId?: number | null;
    /**
     * Calculated value of this item at list price. Does not include tax, shipping, or discounts.
     */
    @sField({ apiName: 'ListTotal__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'List Total', externalId: false })
    public readonly listTotal?: number | null;
    @sField({ apiName: 'OrderItemId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Order Item ID', externalId: true })
    public orderItemId?: number | null;
    @sField({ apiName: 'Project__r', createable: false, updateable: false, required: false, reference: () => { return Project }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Project', externalId: false })
    public project?: Project;
    @sField({ apiName: 'Project__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Project', externalId: false })
    public projectId?: string | null;
    @sField({ apiName: 'ProofId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Proof ID', externalId: true })
    public proofId?: string | null;
    @sField({ apiName: 'Proof__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof', externalId: false })
    public proof?: Proof;
    @sField({ apiName: 'Proof__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof', externalId: false })
    public proofSId?: string | null;
    @sField({ apiName: 'Quantity__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Quantity', externalId: false })
    public quantity?: number | null;
    @sField({ apiName: 'Reorder__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Reorder', externalId: false })
    public reorder?: boolean | null;
    @sField({ apiName: 'ShipVia__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Ship Via', externalId: false })
    public shipVia?: string | null;
    @sField({ apiName: 'Status__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Status', externalId: false })
    public status?: string | null;
    @sField({ apiName: 'Total__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.CURRENCY, salesforceLabel: 'Total', externalId: false })
    public total?: number | null;
    @sField({ apiName: 'Courier__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Courier', externalId: false })
    public courier?: string | null;
    @sField({ apiName: 'ShippingType__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Shipping Type', externalId: false })
    public shippingType?: string | null;
    @sField({ apiName: 'TrackingNumber__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Tracking Number', externalId: false })
    public trackingNumber?: string | null;
    @sField({ apiName: 'ShipDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Ship Date', externalId: false })
    public shipDate?: CalendarDate | null;
    @sField({ apiName: 'TrackingUrl__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Tracking URL', externalId: false })
    public readonly trackingUrl?: string | null;
    @sField({ apiName: 'IsReorder__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is Reorder', externalId: false })
    public readonly isReorder?: string | null;
    @sField({ apiName: 'OrderAddressesLastModifiedTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Order Addresses Last Modified Time', externalId: false })
    public orderAddressesLastModifiedTime?: Date | null;
    @sField({ apiName: 'DaysToShip__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days To Ship', externalId: false })
    public readonly daysToShip?: number | null;
    @sField({ apiName: 'IsWallArt__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is Wall Art', externalId: false })
    public readonly isWallArt?: string | null;
    @sField({ apiName: 'Shipment__r', createable: false, updateable: false, required: false, reference: () => { return Shipment }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Shipment', externalId: false })
    public shipment?: Shipment;
    @sField({ apiName: 'Shipment__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Shipment', externalId: false })
    public shipmentId?: string | null;
    @sField({ apiName: 'Returned__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Returned', externalId: false })
    public returned?: boolean | null;
    /**
     * If populated, this will ensure that an order item doesn't go out more than a few days before this date. If blank, this order item will go out ASAP.
     */
    @sField({ apiName: 'ScheduledShipDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Scheduled Ship Date', externalId: false })
    public scheduledShipDate?: CalendarDate | null;
    @sField({ apiName: 'FormatEnumName__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Format Enum Name', externalId: false })
    public readonly formatEnumName?: string | null;
    @sField({ apiName: 'FormatMaxShortDimension__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Format Max Short Dimension', externalId: false })
    public readonly formatMaxShortDimension?: number | null;
    @sField({ apiName: 'FormatTotalAreaInches__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Format Total Area Inches', externalId: false })
    public readonly formatTotalAreaInches?: number | null;
    @sField({ apiName: 'FormatType__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Format Type', externalId: false })
    public readonly formatType?: string | null;
    @sField({ apiName: 'DeliveryTypeDisplay__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Delivery Type', externalId: false })
    public readonly deliveryTypeDisplay?: string | null;
    @sField({ apiName: 'ShipmentCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Number of Shipments', externalId: false })
    public readonly shipmentCount?: number | null;
    /**
     * Add-ons items supplement the main item and don't count towards tallied quantities
     */
    @sField({ apiName: 'AddOn__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Add-On', externalId: false })
    public addOn?: boolean | null;
    /**
     * When an order is placed, we promised that this item will ship by this date
     */
    @sField({ apiName: 'PromisedShipDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Promised Ship Date', externalId: false })
    public promisedShipDate?: CalendarDate | null;
    @sField({ apiName: 'Product__r', createable: false, updateable: false, required: false, reference: () => { return Product2 }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Product', externalId: false })
    public product?: Product2;
    @sField({ apiName: 'Product__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Product', externalId: false })
    public productId?: string | null;
    /**
     * Checked if this product can't be personalized and isn't expected to have a proof
     */
    @sField({ apiName: 'Static__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Static', externalId: false })
    public static?: boolean | null;
    @sField({ apiName: 'ProofStatus__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Proof Status', externalId: false })
    public readonly proofStatus?: string | null;
    @sField({ apiName: 'InitialQuantity__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Initial Quantity', externalId: false })
    public initialQuantity?: number | null;
    @sField({ apiName: 'OrderItemCounter__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Order Item Counter', externalId: false })
    public readonly orderItemCounter?: number | null;
    @sField({ apiName: 'FormatCode__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Format Code', externalId: false })
    public readonly formatCode?: string | null;
    @sField({ apiName: 'CanonicalCategoryName__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Canonical Category Name', externalId: false })
    public readonly canonicalCategoryName?: string | null;

    constructor(fields?: OrderItemFields, restInstance?: Rest) {
        super('OrderItem__c', restInstance);
        this.proofs = void 0;
        this.shipmentOrderItems = void 0;
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.order = void 0;
        this.orderId = void 0;
        this.code = void 0;
        this.comment = void 0;
        this.cost = void 0;
        this.deliveryType = void 0;
        this.existsInSalesforce = void 0;
        this.itemId = void 0;
        this.listTotal = void 0;
        this.orderItemId = void 0;
        this.project = void 0;
        this.projectId = void 0;
        this.proofId = void 0;
        this.proof = void 0;
        this.proofSId = void 0;
        this.quantity = void 0;
        this.reorder = void 0;
        this.shipVia = void 0;
        this.status = void 0;
        this.total = void 0;
        this.courier = void 0;
        this.shippingType = void 0;
        this.trackingNumber = void 0;
        this.shipDate = void 0;
        this.trackingUrl = void 0;
        this.isReorder = void 0;
        this.orderAddressesLastModifiedTime = void 0;
        this.daysToShip = void 0;
        this.isWallArt = void 0;
        this.shipment = void 0;
        this.shipmentId = void 0;
        this.returned = void 0;
        this.scheduledShipDate = void 0;
        this.formatEnumName = void 0;
        this.formatMaxShortDimension = void 0;
        this.formatTotalAreaInches = void 0;
        this.formatType = void 0;
        this.deliveryTypeDisplay = void 0;
        this.shipmentCount = void 0;
        this.addOn = void 0;
        this.promisedShipDate = void 0;
        this.product = void 0;
        this.productId = void 0;
        this.static = void 0;
        this.proofStatus = void 0;
        this.initialQuantity = void 0;
        this.orderItemCounter = void 0;
        this.formatCode = void 0;
        this.canonicalCategoryName = void 0;
        this.__UUID = OrderItem.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'OrderItem__c' = 'OrderItem__c';
    public readonly _TYPE_: 'OrderItem__c' = 'OrderItem__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<OrderItem>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : OrderItem.getPropertiesMeta<FieldProps<OrderItem>, OrderItem>(OrderItem)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<OrderItem>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<OrderItem[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(OrderItem, qryParam) : qryParam;
        return await RestObject.query<OrderItem>(OrderItem, qry, opts);

    }

    public static fromSFObject(sob: SObject): OrderItem {
        return new OrderItem().mapFromQuery(sob);
    }
}
