import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { OrderItem, ProofReviewer, ProofRevision, ProofSpecialInstruction, RecordType, User, Account, Project, Product2 } from "./";

export type ProofFields = Partial<FieldProps<Proof>>;

/**
 * Generated class for Proof__c
 */
export class Proof extends RestObject {
    @sField({ apiName: 'OrderItems__r', createable: false, updateable: false, required: false, reference: () => { return OrderItem }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'OrderItems__r', externalId: false })
    public orderItems?: OrderItem[];
    @sField({ apiName: 'Reviewers__r', createable: false, updateable: false, required: false, reference: () => { return ProofReviewer }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewers__r', externalId: false })
    public reviewers?: ProofReviewer[];
    @sField({ apiName: 'Revisions__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevision }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revisions__r', externalId: false })
    public revisions?: ProofRevision[];
    @sField({ apiName: 'SpecialInstructions__r', createable: false, updateable: false, required: false, reference: () => { return ProofSpecialInstruction }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'SpecialInstructions__r', externalId: false })
    public specialInstructions?: ProofSpecialInstruction[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'OwnerId', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public ownerId?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Proof Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'RecordType', createable: false, updateable: false, required: false, reference: () => { return RecordType }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Record Type ID', externalId: false })
    public recordType?: RecordType;
    @sField({ apiName: 'RecordTypeId', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Record Type ID', externalId: false })
    public recordTypeId?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'ProofId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Proof ID', externalId: true })
    public proofId?: string | null;
    /**
     * The time when the proof is sent back the customer is automatically recorded so we can use it for reminders
     */
    @sField({ apiName: 'LastEditCompletedTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Edit Completed Time', externalId: false })
    public lastEditCompletedTime?: Date | null;
    @sField({ apiName: 'Status__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Status', externalId: false })
    public status?: string | null;
    @sField({ apiName: 'ApprovalTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Approval Time', externalId: false })
    public approvalTime?: Date | null;
    @sField({ apiName: 'BestRevision__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevision }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Best Revision', externalId: false })
    public bestRevision?: ProofRevision;
    @sField({ apiName: 'BestRevision__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Best Revision', externalId: false })
    public bestRevisionId?: string | null;
    @sField({ apiName: 'Customer__r', createable: false, updateable: false, required: false, reference: () => { return Account }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customer?: Account;
    @sField({ apiName: 'Customer__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customerId?: string | null;
    @sField({ apiName: 'LastEmailTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Email Time', externalId: false })
    public lastEmailTime?: Date | null;
    @sField({ apiName: 'LastPrintTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Print Time', externalId: false })
    public lastPrintTime?: Date | null;
    /**
     * If checked, we will not send the customer reminders to approve the current Best Revision.
     */
    @sField({ apiName: 'MuteEmailReminders__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Mute Email Reminders', externalId: false })
    public muteEmailReminders?: boolean | null;
    /**
     * If checked, we will print the order's return address on all envelopes for this item. This only applies to Send to Self or Address & Message. There must be a return address otherwise there will be an error when we generate the PDFs for the order.
     */
    @sField({ apiName: 'HasPrintedReturnAddressEnvelopes__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Has Printed Return Address Envelopes', externalId: false })
    public hasPrintedReturnAddressEnvelopes?: boolean | null;
    /**
     * Only use this if we need the printer to manually look at this proof. This may delay shipment.
     *
     * Make sure you are very specific in your comments as this will be applied to Send to Self, Mail & Message,  and all delivery types.
     */
    @sField({ apiName: 'SpecialInstructionsForPrinter__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Special Instructions For Printer', externalId: false })
    public specialInstructionsForPrinter?: string | null;
    @sField({ apiName: 'NumberOfRevisions__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Number of Revisions', externalId: false })
    public readonly numberOfRevisions?: number | null;
    /**
     * Check this once you're claimed a proof to prevent us from automatically returning it back to the queue after an hour
     */
    @sField({ apiName: 'HoldAtCurrentOwner__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Hold At Current Owner', externalId: false })
    public holdAtCurrentOwner?: boolean | null;
    @sField({ apiName: 'Priority__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Priority', externalId: false })
    public priority?: number | null;
    @sField({ apiName: 'Code__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Code', externalId: false })
    public code?: string | null;
    @sField({ apiName: 'ItemId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Item ID', externalId: false })
    public itemId?: number | null;
    @sField({ apiName: 'Image__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Image', externalId: false })
    public readonly image?: string | null;
    @sField({ apiName: 'Project__r', createable: false, updateable: false, required: false, reference: () => { return Project }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Project', externalId: false })
    public project?: Project;
    @sField({ apiName: 'Project__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Project', externalId: false })
    public projectId?: string | null;
    @sField({ apiName: 'Edit__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Edit', externalId: false })
    public readonly edit?: string | null;
    @sField({ apiName: 'Paid__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Paid', externalId: false })
    public paid?: boolean | null;
    @sField({ apiName: 'OrderItem__r', createable: false, updateable: false, required: false, reference: () => { return OrderItem }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order Item', externalId: false })
    public orderItem?: OrderItem;
    @sField({ apiName: 'OrderItem__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order Item', externalId: false })
    public orderItemId?: string | null;
    @sField({ apiName: 'LastStatus__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Last Status', externalId: true })
    public lastStatus?: string | null;
    @sField({ apiName: 'LastQueue__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Last Queue', externalId: false })
    public lastQueue?: string | null;
    @sField({ apiName: 'ActiveReviewer__r', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Active Reviewer', externalId: false })
    public activeReviewer?: User;
    @sField({ apiName: 'ActiveReviewer__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Active Reviewer', externalId: false })
    public activeReviewerId?: string | null;
    @sField({ apiName: 'ApprovalUrl__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Approval URL', externalId: false })
    public readonly approvalUrl?: string | null;
    @sField({ apiName: 'Claim__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Claim', externalId: false })
    public readonly claim?: string | null;
    @sField({ apiName: 'DateFirstApproved__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Date First Approved', externalId: false })
    public dateFirstApproved?: Date | null;
    @sField({ apiName: 'HasInternalEdits__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Has Internal Edits', externalId: false })
    public hasInternalEdits?: boolean | null;
    @sField({ apiName: 'IsOwnedByCurrentUser__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is Owned By Current User', externalId: false })
    public readonly isOwnedByCurrentUser?: string | null;
    @sField({ apiName: 'IsOwnedByGroup__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is Owned By Group', externalId: false })
    public readonly isOwnedByGroup?: string | null;
    @sField({ apiName: 'IsReviewableByCurrentUser__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is Reviewable By Current User', externalId: false })
    public readonly isReviewableByCurrentUser?: string | null;
    @sField({ apiName: 'Product__r', createable: false, updateable: false, required: false, reference: () => { return Product2 }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Product', externalId: false })
    public product?: Product2;
    @sField({ apiName: 'Product__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Product', externalId: false })
    public productId?: string | null;
    @sField({ apiName: 'Reviewer1Lookup__r', createable: false, updateable: false, required: false, reference: () => { return ProofReviewer }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer 1 Lookup', externalId: false })
    public reviewer1Lookup?: ProofReviewer;
    @sField({ apiName: 'Reviewer1Lookup__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer 1 Lookup', externalId: false })
    public reviewer1LookupId?: string | null;
    @sField({ apiName: 'Reviewer1__r', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer 1', externalId: false })
    public reviewer1?: User;
    @sField({ apiName: 'Reviewer1__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer 1', externalId: false })
    public reviewer1Id?: string | null;
    @sField({ apiName: 'Reviewer2Lookup__r', createable: false, updateable: false, required: false, reference: () => { return ProofReviewer }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer 2 Lookup', externalId: false })
    public reviewer2Lookup?: ProofReviewer;
    @sField({ apiName: 'Reviewer2Lookup__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer 2 Lookup', externalId: false })
    public reviewer2LookupId?: string | null;
    @sField({ apiName: 'Reviewer2__r', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer 2', externalId: false })
    public reviewer2?: User;
    @sField({ apiName: 'Reviewer2__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewer 2', externalId: false })
    public reviewer2Id?: string | null;
    @sField({ apiName: 'NumberOfReviewers__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Number of Reviewers', externalId: false })
    public readonly numberOfReviewers?: number | null;
    @sField({ apiName: 'ClaimTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Claim Time', externalId: false })
    public claimTime?: Date | null;
    /**
     * Check this and save the proof to return it to the queue in case somebody is locking a proof you need to edit.
     *
     * CAUTION - checking this will prevent the current owner from editing the proof!
     */
    @sField({ apiName: 'ReturnToQueue__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Return to Queue', externalId: false })
    public returnToQueue?: boolean | null;
    /**
     * Priority of the proof from 1-10 (10 is the highest)
     */
    @sField({ apiName: 'TimePriority__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Time Priority', externalId: false })
    public readonly timePriority?: number | null;
    @sField({ apiName: 'FirstDesigner__r', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'First Designer', externalId: false })
    public firstDesigner?: User;
    @sField({ apiName: 'FirstDesigner__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'First Designer', externalId: false })
    public firstDesignerId?: string | null;
    /**
     * For reorders, this is the original proof that was cloned to create this one
     */
    @sField({ apiName: 'BaseProof__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Base Proof', externalId: false })
    public baseProof?: Proof;
    /**
     * For reorders, this is the original proof that was cloned to create this one
     */
    @sField({ apiName: 'BaseProof__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Base Proof', externalId: false })
    public baseProofId?: string | null;
    @sField({ apiName: 'LargeImage__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Large Image', externalId: false })
    public readonly largeImage?: string | null;
    /**
     * Check this and save the proof to return it to the QA queue. This proof must have already been in the QA queue for this to work.
     */
    @sField({ apiName: 'ReturnToQa__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Return to QA', externalId: false })
    public returnToQa?: boolean | null;
    @sField({ apiName: 'ReadyForPrintTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Ready for Print Time', externalId: false })
    public readyForPrintTime?: Date | null;
    @sField({ apiName: 'BestRevisionLastModifiedDate__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Best Revision Last Modified Date', externalId: false })
    public readonly bestRevisionLastModifiedDate?: Date | null;
    @sField({ apiName: 'ShipDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Ship Date', externalId: false })
    public shipDate?: CalendarDate | null;
    /**
     * Number of days it took for this proof to be Ready for Print
     */
    @sField({ apiName: 'DaysToPrint__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days to Print', externalId: false })
    public readonly daysToPrint?: number | null;
    /**
     * Number of days from it took to ship this proof
     */
    @sField({ apiName: 'DaysToShip__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days to Ship', externalId: false })
    public readonly daysToShip?: number | null;
    @sField({ apiName: 'QaCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'QA Count', externalId: false })
    public readonly qaCount?: number | null;
    @sField({ apiName: 'ProductImage__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Product Image', externalId: false })
    public readonly productImage?: string | null;
    @sField({ apiName: 'IsVip__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is VIP', externalId: false })
    public readonly isVip?: string | null;
    @sField({ apiName: 'IsReorder__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Reorder', externalId: false })
    public isReorder?: boolean | null;
    @sField({ apiName: 'DaysSinceBestRevisionLastModified__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days Since Best Revision Last Modified', externalId: false })
    public readonly daysSinceBestRevisionLastModified?: number | null;
    @sField({ apiName: 'CustomerEmail__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.EMAIL, salesforceLabel: 'Customer Email', externalId: false })
    public customerEmail?: string | null;
    @sField({ apiName: 'LowResolutionPhotoCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# of Low Resolution Photos Uploaded', externalId: false })
    public readonly lowResolutionPhotoCount?: number | null;
    @sField({ apiName: 'PhotoCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Total # of Photos Uploaded', externalId: false })
    public readonly photoCount?: number | null;
    @sField({ apiName: 'IsWallArt__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is Wall Art', externalId: false })
    public readonly isWallArt?: string | null;
    @sField({ apiName: 'QuantityOrdered__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Quantity Ordered', externalId: false })
    public readonly quantityOrdered?: number | null;
    @sField({ apiName: 'DaysSinceOrderPlaced__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days Since Order Placed', externalId: false })
    public readonly daysSinceOrderPlaced?: number | null;
    @sField({ apiName: 'DateOrderItemCreated__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Date Order Item Created', externalId: false })
    public readonly dateOrderItemCreated?: Date | null;
    /**
     * Indicates that this proof would have been edited if it wasn't an express proof
     */
    @sField({ apiName: 'ExpressNeededChanges__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Express: Needed Changes', externalId: false })
    public expressNeededChanges?: boolean | null;
    /**
     * Customer selected express flow and accepted that we will not review this proof and will send it directly to print
     */
    @sField({ apiName: 'Express__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Express', externalId: false })
    public express?: boolean | null;
    @sField({ apiName: 'CreatedTime__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Time', externalId: false })
    public readonly createdTime?: Date | null;
    /**
     * Number of days from the time the proof was created until the proof was paid for
     */
    @sField({ apiName: 'DaysToOrder__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Days To Order', externalId: false })
    public readonly daysToOrder?: number | null;
    @sField({ apiName: 'FormatName__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Format Name', externalId: false })
    public readonly formatName?: string | null;
    @sField({ apiName: 'MailedQuantity__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Mailed Quantity', externalId: false })
    public readonly mailedQuantity?: number | null;
    @sField({ apiName: 'ShippedQuantity__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Shipped Quantity', externalId: false })
    public readonly shippedQuantity?: number | null;
    @sField({ apiName: 'AttributesModifiedTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Attributes Modified Time', externalId: false })
    public attributesModifiedTime?: Date | null;
    /**
     * Checked if there is a problem with this proof's PDF assets and we need to resave it
     */
    @sField({ apiName: 'NeedsResaving__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Needs Resaving', externalId: false })
    public needsResaving?: boolean | null;
    @sField({ apiName: 'SurfaceCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Number of Surfaces', externalId: false })
    public readonly surfaceCount?: number | null;
    @sField({ apiName: 'ProjectPriorityBoost__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Project Priority Boost', externalId: false })
    public readonly projectPriorityBoost?: number | null;
    @sField({ apiName: 'ProofCounter__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Proof Counter', externalId: false })
    public readonly proofCounter?: number | null;
    /**
     * The product type, also known as canonical category name.
     */
    @sField({ apiName: 'CanonicalCategoryName__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Canonical Category Name', externalId: false })
    public readonly canonicalCategoryName?: string | null;
    /**
     * Contains information (e.g. rendered file URLs, page count if it's a photobook item) about the order item obtained item editor vendor once the project has been rendered
     */
    @sField({ apiName: 'JsonData__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Json Data', externalId: false })
    public jsonData?: string | null;
    /**
     * PC Core: Routing back to Paper Culture Core team to handle for any reason
     * Proof Error: Select this IN ADDITION to PC Core if the script produces an error
     */
    @sField({ apiName: 'TagsOperational__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.MULTIPICKLIST, salesforceLabel: 'Tags Operational', externalId: false })
    public tagsOperational?: string[] | null;
    /**
     * When Checked, this proof does not get routed to external team
     */
    @sField({ apiName: 'PCCoreOverride__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'PC Core Override', externalId: false })
    public pcCoreOverride?: boolean | null;
    /**
     * PC Core: Routing back to Paper Culture Core team to handle for any reason
     * Proof Error: Select this IN ADDITION to PC Core if the script produces an error
     */
    @sField({ apiName: 'Tags_Operational__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.MULTIPICKLIST, salesforceLabel: 'Tags Operational', externalId: false })
    public tagsOperationalLegacy?: string[] | null;

    constructor(fields?: ProofFields, restInstance?: Rest) {
        super('Proof__c', restInstance);
        this.orderItems = void 0;
        this.reviewers = void 0;
        this.revisions = void 0;
        this.specialInstructions = void 0;
        this.id = void 0;
        this.ownerId = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.recordType = void 0;
        this.recordTypeId = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.proofId = void 0;
        this.lastEditCompletedTime = void 0;
        this.status = void 0;
        this.approvalTime = void 0;
        this.bestRevision = void 0;
        this.bestRevisionId = void 0;
        this.customer = void 0;
        this.customerId = void 0;
        this.lastEmailTime = void 0;
        this.lastPrintTime = void 0;
        this.muteEmailReminders = void 0;
        this.hasPrintedReturnAddressEnvelopes = void 0;
        this.specialInstructionsForPrinter = void 0;
        this.numberOfRevisions = void 0;
        this.holdAtCurrentOwner = void 0;
        this.priority = void 0;
        this.code = void 0;
        this.itemId = void 0;
        this.image = void 0;
        this.project = void 0;
        this.projectId = void 0;
        this.edit = void 0;
        this.paid = void 0;
        this.orderItem = void 0;
        this.orderItemId = void 0;
        this.lastStatus = void 0;
        this.lastQueue = void 0;
        this.activeReviewer = void 0;
        this.activeReviewerId = void 0;
        this.approvalUrl = void 0;
        this.claim = void 0;
        this.dateFirstApproved = void 0;
        this.hasInternalEdits = void 0;
        this.isOwnedByCurrentUser = void 0;
        this.isOwnedByGroup = void 0;
        this.isReviewableByCurrentUser = void 0;
        this.product = void 0;
        this.productId = void 0;
        this.reviewer1Lookup = void 0;
        this.reviewer1LookupId = void 0;
        this.reviewer1 = void 0;
        this.reviewer1Id = void 0;
        this.reviewer2Lookup = void 0;
        this.reviewer2LookupId = void 0;
        this.reviewer2 = void 0;
        this.reviewer2Id = void 0;
        this.numberOfReviewers = void 0;
        this.claimTime = void 0;
        this.returnToQueue = void 0;
        this.timePriority = void 0;
        this.firstDesigner = void 0;
        this.firstDesignerId = void 0;
        this.baseProof = void 0;
        this.baseProofId = void 0;
        this.largeImage = void 0;
        this.returnToQa = void 0;
        this.readyForPrintTime = void 0;
        this.bestRevisionLastModifiedDate = void 0;
        this.shipDate = void 0;
        this.daysToPrint = void 0;
        this.daysToShip = void 0;
        this.qaCount = void 0;
        this.productImage = void 0;
        this.isVip = void 0;
        this.isReorder = void 0;
        this.daysSinceBestRevisionLastModified = void 0;
        this.customerEmail = void 0;
        this.lowResolutionPhotoCount = void 0;
        this.photoCount = void 0;
        this.isWallArt = void 0;
        this.quantityOrdered = void 0;
        this.daysSinceOrderPlaced = void 0;
        this.dateOrderItemCreated = void 0;
        this.expressNeededChanges = void 0;
        this.express = void 0;
        this.createdTime = void 0;
        this.daysToOrder = void 0;
        this.formatName = void 0;
        this.mailedQuantity = void 0;
        this.shippedQuantity = void 0;
        this.attributesModifiedTime = void 0;
        this.needsResaving = void 0;
        this.surfaceCount = void 0;
        this.projectPriorityBoost = void 0;
        this.proofCounter = void 0;
        this.canonicalCategoryName = void 0;
        this.jsonData = void 0;
        this.tagsOperational = void 0;
        this.pcCoreOverride = void 0;
        this.tagsOperationalLegacy = void 0;
        this.__UUID = Proof.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Proof__c' = 'Proof__c';
    public readonly _TYPE_: 'Proof__c' = 'Proof__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Proof>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Proof.getPropertiesMeta<FieldProps<Proof>, Proof>(Proof)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Proof>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Proof[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Proof, qryParam) : qryParam;
        return await RestObject.query<Proof>(Proof, qry, opts);

    }

    public static fromSFObject(sob: SObject): Proof {
        return new Proof().mapFromQuery(sob);
    }
}
