import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { Product2, ProofSurface, User } from "./";

export type ProofFormatFields = Partial<FieldProps<ProofFormat>>;

/**
 * Generated class for ProofFormat__c
 */
export class ProofFormat extends RestObject {
    @sField({ apiName: 'Products__r', createable: false, updateable: false, required: false, reference: () => { return Product2 }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Products__r', externalId: false })
    public products?: Product2[];
    @sField({ apiName: 'ProofSurfaces__r', createable: false, updateable: false, required: false, reference: () => { return ProofSurface }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'ProofSurfaces__r', externalId: false })
    public proofSurfaces?: ProofSurface[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'OwnerId', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public ownerId?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Format Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    /**
     * Primary key of the format in the MySQL cards.proof_formats table
     */
    @sField({ apiName: 'Id__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'ID', externalId: true })
    public formatId?: number | null;
    @sField({ apiName: 'EnumName__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Enum Name', externalId: true })
    public enumName?: string | null;
    @sField({ apiName: 'Type__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Type', externalId: false })
    public type?: string | null;
    /**
     * Longest short dimension of all surfaces in pt
     */
    @sField({ apiName: 'MaxShortDimension__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Max Short Dimension', externalId: false })
    public readonly maxShortDimension?: number | null;
    /**
     * Sum of the area of all surfaces in square inches
     */
    @sField({ apiName: 'TotalAreaInches__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Total Area Inches', externalId: false })
    public readonly totalAreaInches?: number | null;

    constructor(fields?: ProofFormatFields, restInstance?: Rest) {
        super('ProofFormat__c', restInstance);
        this.products = void 0;
        this.proofSurfaces = void 0;
        this.id = void 0;
        this.ownerId = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.formatId = void 0;
        this.enumName = void 0;
        this.type = void 0;
        this.maxShortDimension = void 0;
        this.totalAreaInches = void 0;
        this.__UUID = ProofFormat.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofFormat__c' = 'ProofFormat__c';
    public readonly _TYPE_: 'ProofFormat__c' = 'ProofFormat__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofFormat>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofFormat.getPropertiesMeta<FieldProps<ProofFormat>, ProofFormat>(ProofFormat)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofFormat>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofFormat[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofFormat, qryParam) : qryParam;
        return await RestObject.query<ProofFormat>(ProofFormat, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofFormat {
        return new ProofFormat().mapFromQuery(sob);
    }
}
