import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, Account } from "./";

export type CustomerSpecialInstructionFields = Partial<FieldProps<CustomerSpecialInstruction>>;

/**
 * Generated class for CustomerSpecialInstruction__c
 */
export class CustomerSpecialInstruction extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'Account__r', createable: false, updateable: false, required: false, reference: () => { return Account }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Account', externalId: false })
    public account?: Account;
    @sField({ apiName: 'Account__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Account', externalId: false })
    public accountId?: string | null;
    @sField({ apiName: 'Active__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Active', externalId: false })
    public active?: boolean | null;
    @sField({ apiName: 'Description__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Description', externalId: false })
    public description?: string | null;
    /**
     * Ongoing examples: this customer should always have a special back
     * Temporary: the next time this person orders we want to give them 10 free envelopes
     */
    @sField({ apiName: 'InstructionType__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'InstructionType', externalId: false })
    public instructionType?: string | null;
    /**
     * For temporary Customer Special Instructions, estimate the relevant PrintRun date. Leave blank for Ongoing Customer Special Instructions.
     */
    @sField({ apiName: 'EstimatedPrintRunDate__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Estimated Print Run Date for Temp Instr', externalId: false })
    public estimatedPrintRunDate?: CalendarDate | null;
    /**
     * Customer special instructions may persist forever. However, if an instruction is no longer relevant, mark it as archived.
     */
    @sField({ apiName: 'Archived__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Archived', externalId: false })
    public archived?: boolean | null;
    /**
     * Ongoing examples: this customer should always have a special back
     * Temporary: the next time this person orders we want to give them 10 free envelopes
     */
    @sField({ apiName: 'Type__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Type', externalId: false })
    public type?: string | null;

    constructor(fields?: CustomerSpecialInstructionFields, restInstance?: Rest) {
        super('CustomerSpecialInstruction__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.account = void 0;
        this.accountId = void 0;
        this.active = void 0;
        this.description = void 0;
        this.instructionType = void 0;
        this.estimatedPrintRunDate = void 0;
        this.archived = void 0;
        this.type = void 0;
        this.__UUID = CustomerSpecialInstruction.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'CustomerSpecialInstruction__c' = 'CustomerSpecialInstruction__c';
    public readonly _TYPE_: 'CustomerSpecialInstruction__c' = 'CustomerSpecialInstruction__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<CustomerSpecialInstruction>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : CustomerSpecialInstruction.getPropertiesMeta<FieldProps<CustomerSpecialInstruction>, CustomerSpecialInstruction>(CustomerSpecialInstruction)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<CustomerSpecialInstruction>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<CustomerSpecialInstruction[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(CustomerSpecialInstruction, qryParam) : qryParam;
        return await RestObject.query<CustomerSpecialInstruction>(CustomerSpecialInstruction, qry, opts);

    }

    public static fromSFObject(sob: SObject): CustomerSpecialInstruction {
        return new CustomerSpecialInstruction().mapFromQuery(sob);
    }
}
