import { Severity } from '@sentry/types'
import * as Sentry from '@sentry/vue'
import {
  CompositeBatchFailResult,
  compositeRetrieve,
  getAuthorizationUrl,
  requestAccessToken,
  Rest,
  setDefaultConfig,
  StandardRestError
} from 'ts-force'
import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule
} from 'vuex-module-decorators'

import { Account, Order, Proof } from '@/generated'
import store from '@/store'
import { OAuthCallback } from '@/store/modules/types/salesforce'
import createAuthRefreshInterceptor from '@/utils/axios-auth-refresh'

// Using the "My Domain" instance URL instead of login.salesforce.com is necessary for OAuth login via Google
// to be presented initially, and also for CORS when using a refresh token to get a new access token.
export const SALESFORCE_INSTANCE_URL = 'https://pc.my.salesforce.com'
// Hashi-specific
export const SALESFORCE_CLIENT_ID =
  '3MVG9yZ.WNe6byQB1SRML81XKQya.q8od0ghDZoZNHGIRQZ7wv9tfgYgq2m.H0khPjRob4Eyo.6PtkhljrR3h'

/**
 * Constructs an absolute URL to Salesforce production Lightning UI from a Salesforce ID.
 * This URL avoids a few redirects and improves performance compared to linking
 * to `https://pc.my.salesforce.com/{$id}`.
 */
export const salesforceUrl = (salesforceId: string): string =>
  `https://pc.lightning.force.com/lightning/_classic/%2F${salesforceId}`

const SALESFORCE_ACCESS_TOKEN_KEY = 'salesforceAccessToken'
const SALESFORCE_REFRESH_TOKEN_KEY = 'salesforceRefreshToken'

const name = 'salesforce'
if (module.hot) {
  store.unregisterModule(name)
}

@Module({ name, namespaced: true, dynamic: true, store })
export default class Salesforce extends VuexModule {
  // TODO: For all fetched data, consider creating custom interfaces based on each SOQL query and object schema
  //  to remove `null` where not expected, and to remove fields that don't exist in the query.
  account: Account | null = null

  proofs: Proof[] = []

  orders: Order[] = []

  accessToken: string | null = null

  refreshToken: string | null = localStorage.getItem(
    SALESFORCE_REFRESH_TOKEN_KEY
  )

  // TODO: Refactor repository to normalize data for efficient lookups with vuex-orm
  get proofByProofId() {
    return (proofId: string): Proof | null => {
      return this.proofs.find((proof) => proof.proofId === proofId) || null
    }
  }

  // TODO: Refactor repository to normalize data for efficient lookups with vuex-orm
  get proofBySalesforceId() {
    return (salesforceId: string): Proof | null => {
      return this.proofs.find((proof) => proof.id === salesforceId) || null
    }
  }

  @Mutation
  setAccessToken(accessToken: string | null): void {
    if (accessToken !== null) {
      localStorage.setItem(SALESFORCE_ACCESS_TOKEN_KEY, accessToken)
      setDefaultConfig({
        accessToken,
        instanceUrl: SALESFORCE_INSTANCE_URL
      })
    } else {
      localStorage.removeItem(SALESFORCE_ACCESS_TOKEN_KEY)
    }
    this.accessToken = accessToken
  }

  @Mutation
  setRefreshToken(refreshToken: string | null): void {
    if (refreshToken !== null) {
      localStorage.setItem(SALESFORCE_REFRESH_TOKEN_KEY, refreshToken)
    } else {
      localStorage.removeItem(SALESFORCE_REFRESH_TOKEN_KEY)
    }
    this.refreshToken = refreshToken
  }

  @Action
  async setAccessTokenAndConfigureTokenRefresh(
    accessToken: string
  ): Promise<void> {
    const hasExistingAccessToken = !!this.accessToken

    // Manually commit the mutation first so that default config is initialized before
    // the interceptor is set up, otherwise the interceptor won't work properly.
    // This also allows us to skip running the mutation if there's no access token,
    // whereas if we use the annotation, it will run every time.
    this.context.commit('setAccessToken', accessToken)

    // Set up access token refresh, but only once so that duplicate interceptors aren't registered
    if (!hasExistingAccessToken) {
      // Get default instance to configure it with interceptor
      const restInstance = new Rest()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const refreshAuthLogic = async (failedRequest: any) => {
        const newToken = await this.refreshAccessToken()
        const newAuthHeader = `Bearer ${newToken}`
        failedRequest.response.config.headers['Authorization'] = newAuthHeader
        // Also update our instance so any additional request use the new token
        restInstance.request.defaults.headers['Authorization'] = newAuthHeader
      }

      // Intercept 401 responses and silently refresh the access token and retry
      createAuthRefreshInterceptor(restInstance.request, refreshAuthLogic, {
        // CAUTION: This will attempt to refresh the access token on any network failure
        // because Salesforce doesn't return CORS headers on a 401 Unauthorized response,
        // so we can't differentiate between a loss of connectivity to Salesforce and
        // an invalid access token. Despite being coarse, it should work as intended in most
        // scenarios.
        interceptNetworkError: true
      })
    }
  }

  @Action
  async loadAccessToken(): Promise<string | null> {
    const accessToken = localStorage.getItem(SALESFORCE_ACCESS_TOKEN_KEY)
    if (accessToken) {
      await this.setAccessTokenAndConfigureTokenRefresh(accessToken)
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Loaded Salesforce access token from local storage'
      })
    }
    return accessToken
  }

  /**
   * Returns a Promise that's resolved once the new access token is fetched.
   * MUST be invoked in a click handler, otherwise the popup will be blocked.
   */
  @Action
  getAccessToken(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Login must occur in a new window because it cannot be iframed due to Salesforce
      // security settings that prevent clickjacking.
      const popup = window.open(
        getAuthorizationUrl({
          response_type: 'code',
          instanceUrl: SALESFORCE_INSTANCE_URL,
          client_id: SALESFORCE_CLIENT_ID,
          redirect_uri:
            (location.origin === 'http://localhost:8080'
              ? location.origin
              : // Production callback URL is used because Netlify preview URLs are dynamic.
                // We'll use production to send URLs to preview instances after validating the origin.
                'https://hashi.paperculture.com') + '/oauth-callback',
          // Doesn't seem to make a difference, but it can't hurt in case things change
          display: 'popup',
          // Callback URL will use origin for postMessage security
          state: JSON.stringify({
            origin: location.origin
          })
        }),
        '_blank',
        'width=500,height=600'
      )

      const popupClosedPoller = setInterval(() => {
        if (popup?.closed) {
          clearInterval(popupClosedPoller)
          reject(new Error('Login canceled by user'))
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          window.removeEventListener('message', setTokens)
        }
      }, 500)

      const setTokens = async (event: MessageEvent) => {
        if (
          event.origin !== 'http://localhost:8080' &&
          event.origin !== 'https://hashi.paperculture.com'
        ) {
          // Ignore message and keep listening for the one we expect
          return
        }

        // Stop checking to see if the login was canceled
        clearInterval(popupClosedPoller)
        // We only expect to get one message
        window.removeEventListener('message', setTokens)

        if (typeof event.data == 'object') {
          const data = event.data as OAuthCallback
          if (data.accessToken && data.refreshToken) {
            await this.setAccessTokenAndConfigureTokenRefresh(data.accessToken)
            this.setRefreshToken(data.refreshToken)
            resolve()
          } else {
            const error =
              'Access token and/or refresh token not present in OAuthCallback'
            console.error(error, data)
            reject(new Error(error))
          }
        } else {
          // Don't reject in case this message came from something unexpected, like an extension
          console.error('Object expected for OAuthCallback data', event.data)
        }
      }
      window.addEventListener('message', setTokens)
    })
  }

  /**
   * Uses the refresh token to get a new access token.
   *
   * If there's a problem, all tokens will be cleared to force the user to log back in.
   */
  @Action
  async refreshAccessToken(): Promise<void> {
    if (this.refreshToken) {
      try {
        const response = await requestAccessToken({
          grant_type: 'refresh_token',
          instanceUrl: SALESFORCE_INSTANCE_URL,
          client_id: SALESFORCE_CLIENT_ID,
          refresh_token: this.refreshToken
        })
        this.setAccessToken(response.access_token)
      } catch (e) {
        console.warn('Error refreshing Salesforce access token', e)
        this.clearTokens()
      }
    } else {
      throw new Error(
        'Cannot refresh access token because no refresh token was found'
      )
    }
  }

  /**
   * Delete Salesforce tokens from the store and local storage, which will effectively sign the user out of
   * Salesforce, in Hashi. The tokens themselves will not be invalidated.
   */
  @Action
  clearTokens(): void {
    this.setAccessToken(null)
    this.setRefreshToken(null)
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Cleared Salesforce access tokens'
    })
  }

  @MutationAction({ mutate: ['account', 'proofs', 'orders'] })
  async fetchAll(
    email: string
  ): Promise<{
    proofs: Proof[] | CompositeBatchFailResult<StandardRestError[]>
    orders: Order[] | CompositeBatchFailResult<StandardRestError[]>
    account: Account | null
  }> {
    const [accounts, proofs, orders] = await compositeRetrieve(
      Account,
      Proof,
      Order
    )(
      (account) => ({
        select: [
          ...account.select(
            'id',
            'firstName',
            'lastName',
            'loginName',
            'clientId',
            'timeZone',
            'weddingDate',
            'emailCollectionDate',
            'registrationDate',
            'vip',
            'amountSpent',
            'orderCount'
          ),
          account.subQuery('negatives', (negative) => {
            return {
              select: [
                ...negative.select(
                  'id',
                  'name',
                  'orderId',
                  'negativeResponsibility',
                  'category',
                  'zendeskTicketUrl',
                  'description',
                  'resolutionDescription',
                  'shippingUpgrade',
                  'reprinted',
                  'creditGiven',
                  'refundGiven',
                  'createdDate'
                ),
                ...negative.parent('createdBy').select('id', 'name', 'email')
              ],
              orderBy: {
                field: negative.select('createdDate'),
                order: 'DESC'
              }
            }
          }),
          account.subQuery(
            'customerSpecialInstructions',
            (specialInstruction) => {
              return {
                select: [
                  ...specialInstruction.select(
                    'id',
                    'name',
                    'type',
                    'description',
                    'archived',
                    'createdDate'
                  ),
                  ...specialInstruction
                    .parent('createdBy')
                    .select('id', 'name', 'email')
                ],
                orderBy: {
                  field: specialInstruction.select('createdDate'),
                  order: 'DESC'
                }
              }
            }
          ),
          account.subQuery('customerPromotions', (customerPromotion) => {
            return {
              select: customerPromotion.select(
                'id',
                'name',
                'effectiveStartTime',
                'effectiveEndTime',
                'isActive',
                'remainingUsesCount'
              ),
              orderBy: {
                field: customerPromotion.select('createdDate'),
                order: 'DESC'
              }
            }
          }),
          account.subQuery('delightedResponses', (delightedResponse) => {
            return {
              select: delightedResponse.select(
                'score',
                'comment',
                'properties',
                'timestamp'
              ),
              orderBy: {
                field: delightedResponse.select('timestamp'),
                order: 'DESC'
              }
            }
          }),
          account.subQuery('sampleRequests', (sampleRequest) => {
            return {
              select: sampleRequest.select(
                'id',
                'type',
                'isFulfillable',
                'fulfillmentDate',
                'eventDate',
                'eventStyle',
                'guestCount',
                'firstName',
                'lastName',
                'address1',
                'address2',
                'address3',
                'city',
                'state',
                'zipCode',
                'phone',
                'createdDate'
              ),
              orderBy: {
                field: sampleRequest.select('createdDate'),
                order: 'DESC'
              }
            }
          })
        ],
        where: [
          {
            field: account.select('loginName'),
            op: '=',
            val: email
          }
        ],
        limit: 1
      }),
      (proof) => ({
        select: [
          ...proof.select(
            'id',
            'name',
            'proofId',
            'formatName',
            'canonicalCategoryName',
            'orderItemId',
            'express',
            'muteEmailReminders',
            'paid',
            'status',
            'createdDate',
            'lastModifiedDate',
            'dateFirstApproved',
            'approvalTime',
            'dateOrderItemCreated',
            'readyForPrintTime',
            'shipDate',
            'bestRevisionId',
            'lastModifiedDate',
            // Can't get these out of `...proof.parent('product').select('itemId', 'itemCode')` because
            // `Product2` isn't accessible for Platform licenses.
            'itemId',
            'code'
          ),
          ...proof.parent('baseProof').select('id', 'proofId'),
          proof.parent('recordType').select('name'),
          proof.parent('project').select('projectId'),
          ...proof.parent('firstDesigner').select('id', 'name', 'email'),
          proof.subQuery('revisions', (revision) => {
            return {
              select: [
                ...revision.select(
                  'id',
                  'name',
                  'status',
                  'baseRevision',
                  'revisionNumber',
                  'proofUrl',
                  'rawProofUrl',
                  'csrComments',
                  'internalComments',
                  'customerComments',
                  'toCustomerComments',
                  'toCustomerCommentsImportant',
                  'createdDate',
                  'lastModifiedDate'
                ),
                ...revision.parent('designer').select('id', 'name', 'email')
              ],
              orderBy: {
                field: revision.select('revisionNumber')
              }
            }
          }),
          proof.subQuery('specialInstructions', (specialInstruction) => {
            return {
              select: [
                ...specialInstruction.select(
                  'id',
                  'name',
                  'type',
                  'description',
                  'complete',
                  'createdDate'
                ),
                ...specialInstruction
                  .parent('createdBy')
                  .select('id', 'name', 'email')
              ],
              orderBy: {
                field: specialInstruction.select('createdDate'),
                order: 'DESC'
              }
            }
          })
        ],
        where: [
          {
            field: proof.parent('customer').select('loginName'),
            op: '=',
            val: email
          }
        ],
        orderBy: {
          field: proof.select('lastModifiedDate'),
          order: 'DESC'
        }
      }),
      (order) => ({
        select: [
          ...order.select('id', 'orderNumber', 'phoneNumber', 'createdDate'),
          order.subQuery('orderSpecialInstructions', (specialInstruction) => {
            return {
              select: [
                ...specialInstruction.select(
                  'id',
                  'name',
                  'type',
                  'description',
                  'complete',
                  'createdDate'
                ),
                ...specialInstruction
                  .parent('createdBy')
                  .select('id', 'name', 'email')
              ],
              orderBy: {
                field: specialInstruction.select('createdDate'),
                order: 'DESC'
              }
            }
          })
        ],
        where: [
          {
            field: order.parent('customer').select('loginName'),
            op: '=',
            val: email
          }
        ],
        orderBy: {
          field: order.select('createdDate'),
          order: 'DESC'
        }
      })
    )
    Sentry.addBreadcrumb({
      category: 'salesforce',
      message: 'fetchAll',
      level: Severity.Debug,
      data: {
        accounts,
        proofs,
        orders
      }
    })

    return {
      // Account may not exist
      account: Array.isArray(accounts) && accounts.length ? accounts[0] : null,
      // If proofs or orders have an error, the returned error object shouldn't be written to the state
      proofs: Array.isArray(proofs) ? proofs : [],
      orders: Array.isArray(orders) ? orders : []
    }
  }
}
