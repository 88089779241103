import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { Order, User } from "./";

export type AddressFields = Partial<FieldProps<Address>>;

/**
 * Generated class for Address__c
 */
export class Address extends RestObject {
    @sField({ apiName: 'Orders__r', createable: false, updateable: false, required: false, reference: () => { return Order }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Orders__r', externalId: false })
    public orders?: Order[];
    @sField({ apiName: 'Orders1__r', createable: false, updateable: false, required: false, reference: () => { return Order }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Orders1__r', externalId: false })
    public orders1?: Order[];
    @sField({ apiName: 'Orders2__r', createable: false, updateable: false, required: false, reference: () => { return Order }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Orders2__r', externalId: false })
    public orders2?: Order[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'OwnerId', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public ownerId?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'Address1__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Address 1', externalId: false })
    public address1?: string | null;
    @sField({ apiName: 'Address2__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Address 2', externalId: false })
    public address2?: string | null;
    @sField({ apiName: 'City__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'City', externalId: false })
    public city?: string | null;
    @sField({ apiName: 'Zip__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Zip', externalId: false })
    public zip?: string | null;
    @sField({ apiName: 'State__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'State', externalId: false })
    public state?: string | null;
    @sField({ apiName: 'IntCityStateZip__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Int City State Zip', externalId: false })
    public intCityStateZip?: string | null;
    @sField({ apiName: 'Country__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Country', externalId: false })
    public country?: string | null;
    @sField({ apiName: 'TableIdHostname__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Table + ID + Hostname', externalId: true })
    public tableIdHostname?: string | null;
    @sField({ apiName: 'IsInternational__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Is International', externalId: false })
    public readonly isInternational?: string | null;
    @sField({ apiName: 'Address3__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Address 3', externalId: false })
    public address3?: string | null;

    constructor(fields?: AddressFields, restInstance?: Rest) {
        super('Address__c', restInstance);
        this.orders = void 0;
        this.orders1 = void 0;
        this.orders2 = void 0;
        this.id = void 0;
        this.ownerId = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.address1 = void 0;
        this.address2 = void 0;
        this.city = void 0;
        this.zip = void 0;
        this.state = void 0;
        this.intCityStateZip = void 0;
        this.country = void 0;
        this.tableIdHostname = void 0;
        this.isInternational = void 0;
        this.address3 = void 0;
        this.__UUID = Address.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Address__c' = 'Address__c';
    public readonly _TYPE_: 'Address__c' = 'Address__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Address>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Address.getPropertiesMeta<FieldProps<Address>, Address>(Address)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Address>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Address[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Address, qryParam) : qryParam;
        return await RestObject.query<Address>(Address, qry, opts);

    }

    public static fromSFObject(sob: SObject): Address {
        return new Address().mapFromQuery(sob);
    }
}
