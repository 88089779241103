import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, Account, Promotion } from "./";

export type CustomerPromotionFields = Partial<FieldProps<CustomerPromotion>>;

/**
 * Generated class for CustomerPromotion__c
 */
export class CustomerPromotion extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Promotion Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    /**
     * Populated if the referenced promotion has unique codes per customer. Otherwise, we'll apply the Promotion Code from the Promotion object.
     */
    @sField({ apiName: 'CouponCode__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Coupon Code', externalId: true })
    public couponCode?: string | null;
    @sField({ apiName: 'Customer__r', createable: false, updateable: false, required: false, reference: () => { return Account }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customer?: Account;
    @sField({ apiName: 'Customer__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customerId?: string | null;
    @sField({ apiName: 'Promotion__r', createable: false, updateable: false, required: false, reference: () => { return Promotion }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Promotion', externalId: false })
    public promotion?: Promotion;
    @sField({ apiName: 'Promotion__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Promotion', externalId: false })
    public promotionId?: string | null;
    /**
     * (Optional) Our frontend won't allow Customer Promotions to be applied after the end time. This allows us to add time-limited or expiring promotions. Setting this here in the Customer Promotion takes precedence over the End Time in the related Promotion.
     */
    @sField({ apiName: 'EndTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'End Time', externalId: false })
    public endTime?: Date | null;
    /**
     * (Optional) Our frontend won't allow Customer Promotions to be applied before the start time. Setting this here in the Customer Promotion takes precedence over the Start Time in the related Promotion.
     */
    @sField({ apiName: 'StartTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Start Time', externalId: false })
    public startTime?: Date | null;
    /**
     * If this is empty, the customer can use the promotion an unlimited number of times. Otherwise, the promotion can be used on this number of future orders.
     */
    @sField({ apiName: 'RemainingUsesCount__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Number of Remaining Uses', externalId: false })
    public remainingUsesCount?: number | null;
    @sField({ apiName: 'LeadGenRedemptionId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Lead Gen Redemption ID', externalId: true })
    public leadGenRedemptionId?: number | null;
    /**
     * Actual code that will be applied by this Customer Promotion. This is the Customer Promotion's Coupon Code if it's defined, otherwise it falls back to the parent Promotion's Promotion Code.
     */
    @sField({ apiName: 'EffectiveCode__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Effective Code', externalId: false })
    public readonly effectiveCode?: string | null;
    /**
     * Customer Promotions aren't valid after this date and time if it is defined. This is the Customer Promotion's End Time if it's defined, otherwise it falls back to the parent Promotion's End Time.
     */
    @sField({ apiName: 'EffectiveEndTime__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Effective End Time', externalId: false })
    public readonly effectiveEndTime?: Date | null;
    /**
     * Customer Promotions aren't valid before this time if it is defined. This is the Customer Promotion's Start Time if it's defined, otherwise it falls back to the parent Promotion's Start Time.
     */
    @sField({ apiName: 'EffectiveStartTime__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Effective Start Time', externalId: false })
    public readonly effectiveStartTime?: Date | null;
    /**
     * Checked if this Customer Promotion is still valid: within the start and end time and has uses remaining.
     */
    @sField({ apiName: 'IsActive__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Active', externalId: false })
    public readonly isActive?: boolean | null;

    constructor(fields?: CustomerPromotionFields, restInstance?: Rest) {
        super('CustomerPromotion__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.couponCode = void 0;
        this.customer = void 0;
        this.customerId = void 0;
        this.promotion = void 0;
        this.promotionId = void 0;
        this.endTime = void 0;
        this.startTime = void 0;
        this.remainingUsesCount = void 0;
        this.leadGenRedemptionId = void 0;
        this.effectiveCode = void 0;
        this.effectiveEndTime = void 0;
        this.effectiveStartTime = void 0;
        this.isActive = void 0;
        this.__UUID = CustomerPromotion.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'CustomerPromotion__c' = 'CustomerPromotion__c';
    public readonly _TYPE_: 'CustomerPromotion__c' = 'CustomerPromotion__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<CustomerPromotion>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : CustomerPromotion.getPropertiesMeta<FieldProps<CustomerPromotion>, CustomerPromotion>(CustomerPromotion)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<CustomerPromotion>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<CustomerPromotion[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(CustomerPromotion, qryParam) : qryParam;
        return await RestObject.query<CustomerPromotion>(CustomerPromotion, qry, opts);

    }

    public static fromSFObject(sob: SObject): CustomerPromotion {
        return new CustomerPromotion().mapFromQuery(sob);
    }
}
