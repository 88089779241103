import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, ProofRevision } from "./";

export type UploadedPhotoFields = Partial<FieldProps<UploadedPhoto>>;

/**
 * Generated class for UploadedPhoto__c
 */
export class UploadedPhoto extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Uploaded Photo Name', externalId: false })
    public readonly name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'Revision__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevision }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision', externalId: false })
    public revision?: ProofRevision;
    @sField({ apiName: 'Revision__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision', externalId: false })
    public revisionId?: string | null;
    @sField({ apiName: 'Image__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Image', externalId: false })
    public readonly image?: string | null;
    @sField({ apiName: 'IsLowResolution__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Low Resolution', externalId: false })
    public isLowResolution?: boolean | null;
    @sField({ apiName: 'OriginalHeight__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Original Height', externalId: false })
    public originalHeight?: number | null;
    @sField({ apiName: 'OriginalUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Original URL', externalId: false })
    public originalUrl?: string | null;
    @sField({ apiName: 'OriginalWidth__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Original Width', externalId: false })
    public originalWidth?: number | null;
    @sField({ apiName: 'ScreenHeight__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Screen Height', externalId: false })
    public screenHeight?: number | null;
    @sField({ apiName: 'ScreenUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Screen URL', externalId: false })
    public screenUrl?: string | null;
    @sField({ apiName: 'ScreenWidth__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Screen Width', externalId: false })
    public screenWidth?: number | null;

    constructor(fields?: UploadedPhotoFields, restInstance?: Rest) {
        super('UploadedPhoto__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.revision = void 0;
        this.revisionId = void 0;
        this.image = void 0;
        this.isLowResolution = void 0;
        this.originalHeight = void 0;
        this.originalUrl = void 0;
        this.originalWidth = void 0;
        this.screenHeight = void 0;
        this.screenUrl = void 0;
        this.screenWidth = void 0;
        this.__UUID = UploadedPhoto.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'UploadedPhoto__c' = 'UploadedPhoto__c';
    public readonly _TYPE_: 'UploadedPhoto__c' = 'UploadedPhoto__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<UploadedPhoto>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : UploadedPhoto.getPropertiesMeta<FieldProps<UploadedPhoto>, UploadedPhoto>(UploadedPhoto)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<UploadedPhoto>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<UploadedPhoto[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(UploadedPhoto, qryParam) : qryParam;
        return await RestObject.query<UploadedPhoto>(UploadedPhoto, qry, opts);

    }

    public static fromSFObject(sob: SObject): UploadedPhoto {
        return new UploadedPhoto().mapFromQuery(sob);
    }
}
