import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, ProofRevisionSurface } from "./";

export type ProofPhotoFields = Partial<FieldProps<ProofPhoto>>;

/**
 * Generated class for ProofPhoto__c
 */
export class ProofPhoto extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Photo ID', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'Url__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'URL', externalId: false })
    public url?: string | null;
    @sField({ apiName: 'Conversion__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Conversion', externalId: false })
    public conversion?: string | null;
    @sField({ apiName: 'OriginalUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Original URL', externalId: false })
    public originalUrl?: string | null;
    @sField({ apiName: 'RevisionSurface__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevisionSurface }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Surface', externalId: false })
    public revisionSurface?: ProofRevisionSurface;
    @sField({ apiName: 'RevisionSurface__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Surface', externalId: false })
    public revisionSurfaceId?: string | null;
    @sField({ apiName: 'IsEmbedded__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Embedded', externalId: false })
    public isEmbedded?: boolean | null;
    @sField({ apiName: 'IsLowResolution__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Low Resolution', externalId: false })
    public isLowResolution?: boolean | null;
    @sField({ apiName: 'OriginalHeight__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Original Height', externalId: false })
    public originalHeight?: number | null;
    @sField({ apiName: 'OriginalWidth__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Original Width', externalId: false })
    public originalWidth?: number | null;
    @sField({ apiName: 'Scale__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Scale', externalId: false })
    public scale?: number | null;
    /**
     * Printed DPI at the current scale. We aim for at least 150 DPI. 300 DPI is optimal and any higher than that won't provide any benefit.
     */
    @sField({ apiName: 'Dpi__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'DPI', externalId: false })
    public readonly dpi?: number | null;
    @sField({ apiName: 'IsScaleInPt__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Scale In Pt', externalId: false })
    public isScaleInPt?: boolean | null;
    @sField({ apiName: 'ScreenUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Screen URL', externalId: false })
    public screenUrl?: string | null;
    @sField({ apiName: 'Image__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Image', externalId: false })
    public readonly image?: string | null;
    @sField({ apiName: 'LargeImage__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Large Image', externalId: false })
    public readonly largeImage?: string | null;
    @sField({ apiName: 'OriginalLongDimension__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Original Long Dimension', externalId: false })
    public readonly originalLongDimension?: number | null;
    @sField({ apiName: 'Megapixels__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Megapixels', externalId: false })
    public readonly megapixels?: number | null;

    constructor(fields?: ProofPhotoFields, restInstance?: Rest) {
        super('ProofPhoto__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.url = void 0;
        this.conversion = void 0;
        this.originalUrl = void 0;
        this.revisionSurface = void 0;
        this.revisionSurfaceId = void 0;
        this.isEmbedded = void 0;
        this.isLowResolution = void 0;
        this.originalHeight = void 0;
        this.originalWidth = void 0;
        this.scale = void 0;
        this.dpi = void 0;
        this.isScaleInPt = void 0;
        this.screenUrl = void 0;
        this.image = void 0;
        this.largeImage = void 0;
        this.originalLongDimension = void 0;
        this.megapixels = void 0;
        this.__UUID = ProofPhoto.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofPhoto__c' = 'ProofPhoto__c';
    public readonly _TYPE_: 'ProofPhoto__c' = 'ProofPhoto__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofPhoto>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofPhoto.getPropertiesMeta<FieldProps<ProofPhoto>, ProofPhoto>(ProofPhoto)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofPhoto>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofPhoto[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofPhoto, qryParam) : qryParam;
        return await RestObject.query<ProofPhoto>(ProofPhoto, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofPhoto {
        return new ProofPhoto().mapFromQuery(sob);
    }
}
