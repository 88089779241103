import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, Shipment, OrderItem } from "./";

export type ShipmentOrderItemFields = Partial<FieldProps<ShipmentOrderItem>>;

/**
 * Generated class for ShipmentOrderItem__c
 */
export class ShipmentOrderItem extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Shipment Order Item Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'Shipment__r', createable: false, updateable: false, required: false, reference: () => { return Shipment }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Shipment', externalId: false })
    public shipment?: Shipment;
    @sField({ apiName: 'Shipment__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Shipment', externalId: false })
    public shipmentId?: string | null;
    @sField({ apiName: 'OrderItem__r', createable: false, updateable: false, required: false, reference: () => { return OrderItem }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order Item', externalId: false })
    public orderItem?: OrderItem;
    @sField({ apiName: 'OrderItem__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Order Item', externalId: false })
    public orderItemId?: string | null;
    /**
     * Optionally add this quantity to override the quantity specified in the order item
     */
    @sField({ apiName: 'QuantityOverride__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Quantity Override', externalId: false })
    public quantityOverride?: number | null;

    constructor(fields?: ShipmentOrderItemFields, restInstance?: Rest) {
        super('ShipmentOrderItem__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.shipment = void 0;
        this.shipmentId = void 0;
        this.orderItem = void 0;
        this.orderItemId = void 0;
        this.quantityOverride = void 0;
        this.__UUID = ShipmentOrderItem.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ShipmentOrderItem__c' = 'ShipmentOrderItem__c';
    public readonly _TYPE_: 'ShipmentOrderItem__c' = 'ShipmentOrderItem__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ShipmentOrderItem>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ShipmentOrderItem.getPropertiesMeta<FieldProps<ShipmentOrderItem>, ShipmentOrderItem>(ShipmentOrderItem)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ShipmentOrderItem>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ShipmentOrderItem[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ShipmentOrderItem, qryParam) : qryParam;
        return await RestObject.query<ShipmentOrderItem>(ShipmentOrderItem, qry, opts);

    }

    public static fromSFObject(sob: SObject): ShipmentOrderItem {
        return new ShipmentOrderItem().mapFromQuery(sob);
    }
}
