import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { ProofReviewer, ProofRevisionHistory, ProofRevisionSurface, Proof, UploadedPhoto, User } from "./";

export type ProofRevisionFields = Partial<FieldProps<ProofRevision>>;

/**
 * Generated class for ProofRevision__c
 */
export class ProofRevision extends RestObject {
    @sField({ apiName: 'Reviewers__r', createable: false, updateable: false, required: false, reference: () => { return ProofReviewer }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Reviewers__r', externalId: false })
    public reviewers?: ProofReviewer[];
    @sField({ apiName: 'RevisionHistory__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevisionHistory }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'RevisionHistory__r', externalId: false })
    public revisionHistory?: ProofRevisionHistory[];
    @sField({ apiName: 'RevisionSurfaces__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevisionSurface }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'RevisionSurfaces__r', externalId: false })
    public revisionSurfaces?: ProofRevisionSurface[];
    @sField({ apiName: 'Proofs__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proofs__r', externalId: false })
    public proofs?: Proof[];
    @sField({ apiName: 'UploadedPhotos__r', createable: false, updateable: false, required: false, reference: () => { return UploadedPhoto }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'UploadedPhotos__r', externalId: false })
    public uploadedPhotos?: UploadedPhoto[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Proof Revision Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'Proof__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof', externalId: false })
    public proof?: Proof;
    @sField({ apiName: 'Proof__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof', externalId: false })
    public proofId?: string | null;
    @sField({ apiName: 'RevisionNumber__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Revision Number', externalId: true })
    public revisionNumber?: number | null;
    @sField({ apiName: 'CustomerComments__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Customer Comments', externalId: false })
    public customerComments?: string | null;
    @sField({ apiName: 'CsrComments__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'CSR Comments', externalId: false })
    public csrComments?: string | null;
    @sField({ apiName: 'ProofUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Proof URL', externalId: false })
    public proofUrl?: string | null;
    @sField({ apiName: 'ScriptUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Script URL', externalId: false })
    public scriptUrl?: string | null;
    @sField({ apiName: 'PdfUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'PDF URL', externalId: false })
    public pdfUrl?: string | null;
    @sField({ apiName: 'Status__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Status', externalId: false })
    public status?: string | null;
    @sField({ apiName: 'Source__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Source', externalId: false })
    public source?: string | null;
    @sField({ apiName: 'Designer__r', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Designer', externalId: false })
    public designer?: User;
    @sField({ apiName: 'Designer__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Designer', externalId: false })
    public designerId?: string | null;
    @sField({ apiName: 'AiUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'AI URL', externalId: false })
    public aiUrl?: string | null;
    @sField({ apiName: 'BaseRevision__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Base Revision Number', externalId: false })
    public baseRevision?: number | null;
    @sField({ apiName: 'ActionTaken__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Action Taken', externalId: false })
    public actionTaken?: string | null;
    @sField({ apiName: 'AiSignature__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'AI Signature', externalId: false })
    public aiSignature?: string | null;
    @sField({ apiName: 'InternalComments__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Internal Comments', externalId: false })
    public internalComments?: string | null;
    @sField({ apiName: 'IsCreatedByCsr__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Created By CSR', externalId: false })
    public isCreatedByCsr?: boolean | null;
    @sField({ apiName: 'Orientation__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Orientation', externalId: false })
    public readonly orientation?: string | null;
    @sField({ apiName: 'ProofId_RevisionNumber__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Proof ID  + Revision Number', externalId: true })
    public proofIdRevisionNumber?: string | null;
    @sField({ apiName: 'RawProofUrlHeight__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Raw Proof URL Height', externalId: false })
    public rawProofUrlHeight?: number | null;
    @sField({ apiName: 'RawProofUrlWidth__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Raw Proof URL Width', externalId: false })
    public rawProofUrlWidth?: number | null;
    @sField({ apiName: 'RawProofUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Raw Proof URL', externalId: false })
    public rawProofUrl?: string | null;
    @sField({ apiName: 'ToCustomerCommentsImportant__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'To Customer Comments (Important)', externalId: false })
    public toCustomerCommentsImportant?: string | null;
    @sField({ apiName: 'ToCustomerComments__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'To Customer Comments', externalId: false })
    public toCustomerComments?: string | null;
    @sField({ apiName: 'UserAgent__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'User Agent', externalId: false })
    public userAgent?: string | null;
    @sField({ apiName: 'LowResolutionPhotoCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: '# of Low Resolution Photos', externalId: false })
    public readonly lowResolutionPhotoCount?: number | null;
    @sField({ apiName: 'LargeImage__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Large Image', externalId: false })
    public readonly largeImage?: string | null;
    @sField({ apiName: 'FirstRawProofUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'First Raw Proof URL', externalId: false })
    public firstRawProofUrl?: string | null;
    @sField({ apiName: 'CustomerEmail__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.EMAIL, salesforceLabel: 'Customer Email', externalId: false })
    public customerEmail?: string | null;
    @sField({ apiName: 'PhotoCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Total # of Photos', externalId: false })
    public readonly photoCount?: number | null;
    @sField({ apiName: 'JsonHistory__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'JSON History', externalId: false })
    public jsonHistory?: string | null;
    /**
     * When checked, we send the customer an email notifying them that they're edit requests will be delayed
     */
    @sField({ apiName: 'SentEditRequestsDelayedEmail__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Sent Edit Requests Delayed Email', externalId: false })
    public sentEditRequestsDelayedEmail?: boolean | null;
    /**
     * Gets the AI script to open the latest version of this revision or the latest version of the base revision if this revision has never been saved.
     *
     * CAUTION: You'll be able to save over proofs you don't own, however Internal Edits history won't be created.
     */
    @sField({ apiName: 'GetProofScript__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Get Proof Script', externalId: false })
    public readonly getProofScript?: string | null;
    @sField({ apiName: 'SurfaceCount__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Number of Surfaces', externalId: false })
    public readonly surfaceCount?: number | null;

    constructor(fields?: ProofRevisionFields, restInstance?: Rest) {
        super('ProofRevision__c', restInstance);
        this.reviewers = void 0;
        this.revisionHistory = void 0;
        this.revisionSurfaces = void 0;
        this.proofs = void 0;
        this.uploadedPhotos = void 0;
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.proof = void 0;
        this.proofId = void 0;
        this.revisionNumber = void 0;
        this.customerComments = void 0;
        this.csrComments = void 0;
        this.proofUrl = void 0;
        this.scriptUrl = void 0;
        this.pdfUrl = void 0;
        this.status = void 0;
        this.source = void 0;
        this.designer = void 0;
        this.designerId = void 0;
        this.aiUrl = void 0;
        this.baseRevision = void 0;
        this.actionTaken = void 0;
        this.aiSignature = void 0;
        this.internalComments = void 0;
        this.isCreatedByCsr = void 0;
        this.orientation = void 0;
        this.proofIdRevisionNumber = void 0;
        this.rawProofUrlHeight = void 0;
        this.rawProofUrlWidth = void 0;
        this.rawProofUrl = void 0;
        this.toCustomerCommentsImportant = void 0;
        this.toCustomerComments = void 0;
        this.userAgent = void 0;
        this.lowResolutionPhotoCount = void 0;
        this.largeImage = void 0;
        this.firstRawProofUrl = void 0;
        this.customerEmail = void 0;
        this.photoCount = void 0;
        this.jsonHistory = void 0;
        this.sentEditRequestsDelayedEmail = void 0;
        this.getProofScript = void 0;
        this.surfaceCount = void 0;
        this.__UUID = ProofRevision.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofRevision__c' = 'ProofRevision__c';
    public readonly _TYPE_: 'ProofRevision__c' = 'ProofRevision__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofRevision>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofRevision.getPropertiesMeta<FieldProps<ProofRevision>, ProofRevision>(ProofRevision)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofRevision>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofRevision[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofRevision, qryParam) : qryParam;
        return await RestObject.query<ProofRevision>(ProofRevision, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofRevision {
        return new ProofRevision().mapFromQuery(sob);
    }
}
