import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, ProofRevisionSurface } from "./";

export type ProofRevisionSurfaceOverlayFields = Partial<FieldProps<ProofRevisionSurfaceOverlay>>;

/**
 * Generated class for ProofRevisionSurfaceOverlay__c
 */
export class ProofRevisionSurfaceOverlay extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Revision Surface Overlay Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastActivityDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATE, salesforceLabel: 'Last Activity Date', externalId: false })
    public readonly lastActivityDate?: CalendarDate | null;
    @sField({ apiName: 'ApplyTo__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Apply To', externalId: false })
    public applyTo?: string | null;
    @sField({ apiName: 'Index__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Index', externalId: false })
    public index?: number | null;
    @sField({ apiName: 'RevisionSurface__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevisionSurface }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision Surface', externalId: false })
    public revisionSurface?: ProofRevisionSurface;
    @sField({ apiName: 'RevisionSurface__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision Surface', externalId: false })
    public revisionSurfaceId?: string | null;
    @sField({ apiName: 'ProofUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Proof URL', externalId: false })
    public proofUrl?: string | null;
    @sField({ apiName: 'Id__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'ID', externalId: true })
    public proofRevisionSurfaceOverlayId?: string | null;
    @sField({ apiName: 'PdfUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'PDF URL', externalId: false })
    public pdfUrl?: string | null;

    constructor(fields?: ProofRevisionSurfaceOverlayFields, restInstance?: Rest) {
        super('ProofRevisionSurfaceOverlay__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastActivityDate = void 0;
        this.applyTo = void 0;
        this.index = void 0;
        this.revisionSurface = void 0;
        this.revisionSurfaceId = void 0;
        this.proofUrl = void 0;
        this.proofRevisionSurfaceOverlayId = void 0;
        this.pdfUrl = void 0;
        this.__UUID = ProofRevisionSurfaceOverlay.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofRevisionSurfaceOverlay__c' = 'ProofRevisionSurfaceOverlay__c';
    public readonly _TYPE_: 'ProofRevisionSurfaceOverlay__c' = 'ProofRevisionSurfaceOverlay__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofRevisionSurfaceOverlay>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofRevisionSurfaceOverlay.getPropertiesMeta<FieldProps<ProofRevisionSurfaceOverlay>, ProofRevisionSurfaceOverlay>(ProofRevisionSurfaceOverlay)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofRevisionSurfaceOverlay>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofRevisionSurfaceOverlay[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofRevisionSurfaceOverlay, qryParam) : qryParam;
        return await RestObject.query<ProofRevisionSurfaceOverlay>(ProofRevisionSurfaceOverlay, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofRevisionSurfaceOverlay {
        return new ProofRevisionSurfaceOverlay().mapFromQuery(sob);
    }
}
