import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { User, ProofRevision } from "./";

export type ProofRevisionHistoryFields = Partial<FieldProps<ProofRevisionHistory>>;

/**
 * Generated class for ProofRevisionHistory__c
 */
export class ProofRevisionHistory extends RestObject {
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Revision History Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'Revision__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevision }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision', externalId: false })
    public revision?: ProofRevision;
    @sField({ apiName: 'Revision__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision', externalId: false })
    public revisionId?: string | null;
    @sField({ apiName: 'ActionTaken__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Action Taken', externalId: false })
    public actionTaken?: string | null;
    @sField({ apiName: 'AiUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'AI URL', externalId: false })
    public aiUrl?: string | null;
    @sField({ apiName: 'AssignedToId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Assigned To ID', externalId: false })
    public assignedToId?: string | null;
    @sField({ apiName: 'Notes__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Notes', externalId: false })
    public notes?: string | null;
    @sField({ apiName: 'ProofUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Proof URL', externalId: false })
    public proofUrl?: string | null;
    @sField({ apiName: 'RawProofUrlHeight__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Raw Proof URL Height', externalId: false })
    public rawProofUrlHeight?: number | null;
    @sField({ apiName: 'RawProofUrlWidth__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Raw Proof URL Width', externalId: false })
    public rawProofUrlWidth?: number | null;
    @sField({ apiName: 'RawProofUrl__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.URL, salesforceLabel: 'Raw Proof URL', externalId: false })
    public rawProofUrl?: string | null;
    @sField({ apiName: 'ClaimTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Claim Time', externalId: false })
    public claimTime?: Date | null;
    @sField({ apiName: 'EditTime__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Edit Time (Minutes)', externalId: false })
    public readonly editTime?: number | null;
    @sField({ apiName: 'SurfaceImagesJson__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Surface Images JSON', externalId: false })
    public surfaceImagesJson?: string | null;

    constructor(fields?: ProofRevisionHistoryFields, restInstance?: Rest) {
        super('ProofRevisionHistory__c', restInstance);
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.revision = void 0;
        this.revisionId = void 0;
        this.actionTaken = void 0;
        this.aiUrl = void 0;
        this.assignedToId = void 0;
        this.notes = void 0;
        this.proofUrl = void 0;
        this.rawProofUrlHeight = void 0;
        this.rawProofUrlWidth = void 0;
        this.rawProofUrl = void 0;
        this.claimTime = void 0;
        this.editTime = void 0;
        this.surfaceImagesJson = void 0;
        this.__UUID = ProofRevisionHistory.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofRevisionHistory__c' = 'ProofRevisionHistory__c';
    public readonly _TYPE_: 'ProofRevisionHistory__c' = 'ProofRevisionHistory__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofRevisionHistory>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofRevisionHistory.getPropertiesMeta<FieldProps<ProofRevisionHistory>, ProofRevisionHistory>(ProofRevisionHistory)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofRevisionHistory>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofRevisionHistory[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofRevisionHistory, qryParam) : qryParam;
        return await RestObject.query<ProofRevisionHistory>(ProofRevisionHistory, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofRevisionHistory {
        return new ProofRevisionHistory().mapFromQuery(sob);
    }
}
