import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { CustomerPromotion, User } from "./";

export type PromotionFields = Partial<FieldProps<Promotion>>;

/**
 * Generated class for Promotion__c
 */
export class Promotion extends RestObject {
    @sField({ apiName: 'CustomerPromotions__r', createable: false, updateable: false, required: false, reference: () => { return CustomerPromotion }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'CustomerPromotions__r', externalId: false })
    public customerPromotions?: CustomerPromotion[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'OwnerId', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public ownerId?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    /**
     * Text displayed to the user to explain the details of the promotion. This may be displayed on product pages or in the cart.
     */
    @sField({ apiName: 'FinePrint__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Fine Print', externalId: false })
    public finePrint?: string | null;
    /**
     * General code to be applied for this promotion. If the promotion has no general use code and only has unique coupon codes, this will be blank and the coupon code will be populated in the Customer Promotion junction object.
     */
    @sField({ apiName: 'PromotionCode__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Promotion Code', externalId: true })
    public promotionCode?: string | null;
    /**
     * Unique ID that can be referenced in Customer Promotion data loads
     */
    @sField({ apiName: 'Id__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Id', externalId: true })
    public promotionId?: string | null;
    /**
     * (Optional) Our frontend won't allow Promotions to be applied until the start time.
     */
    @sField({ apiName: 'StartTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Start Time', externalId: false })
    public startTime?: Date | null;
    /**
     * (Optional) Our frontend won't allow Promotions to be applied after the end time. This allows us to add time-limited or expiring promotions.
     */
    @sField({ apiName: 'EndTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'End Time', externalId: false })
    public endTime?: Date | null;
    /**
     * Checked if the promotion should be automatically applied on the review order page
     */
    @sField({ apiName: 'IsAutoApplied__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Is Auto Applied', externalId: false })
    public isAutoApplied?: boolean | null;
    /**
     * Copied to created Customer Promotions on creation to limit the number of uses per customer. If this is empty, there are unlimited uses.
     */
    @sField({ apiName: 'InitialRemainingUsesPerCustomer__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Initial Remaining Uses Per Customer', externalId: false })
    public initialRemainingUsesPerCustomer?: number | null;

    constructor(fields?: PromotionFields, restInstance?: Rest) {
        super('Promotion__c', restInstance);
        this.customerPromotions = void 0;
        this.id = void 0;
        this.ownerId = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.finePrint = void 0;
        this.promotionCode = void 0;
        this.promotionId = void 0;
        this.startTime = void 0;
        this.endTime = void 0;
        this.isAutoApplied = void 0;
        this.initialRemainingUsesPerCustomer = void 0;
        this.__UUID = Promotion.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Promotion__c' = 'Promotion__c';
    public readonly _TYPE_: 'Promotion__c' = 'Promotion__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Promotion>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Promotion.getPropertiesMeta<FieldProps<Promotion>, Promotion>(Promotion)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Promotion>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Promotion[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Promotion, qryParam) : qryParam;
        return await RestObject.query<Promotion>(Promotion, qry, opts);

    }

    public static fromSFObject(sob: SObject): Promotion {
        return new Promotion().mapFromQuery(sob);
    }
}
