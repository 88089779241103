import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { OrderItem, Proof, User, Account, Product2 } from "./";

export type ProjectFields = Partial<FieldProps<Project>>;

/**
 * Generated class for Project__c
 */
export class Project extends RestObject {
    @sField({ apiName: 'OrderItems__r', createable: false, updateable: false, required: false, reference: () => { return OrderItem }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'OrderItems__r', externalId: false })
    public orderItems?: OrderItem[];
    @sField({ apiName: 'Proofs__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proofs__r', externalId: false })
    public proofs?: Proof[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'OwnerId', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Owner ID', externalId: false })
    public ownerId?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Project Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'LastViewedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Viewed Date', externalId: false })
    public readonly lastViewedDate?: Date | null;
    @sField({ apiName: 'LastReferencedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Referenced Date', externalId: false })
    public readonly lastReferencedDate?: Date | null;
    @sField({ apiName: 'ProjectId__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Project ID', externalId: true })
    public projectId?: string | null;
    @sField({ apiName: 'ItemId__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Item ID', externalId: false })
    public itemId?: number | null;
    @sField({ apiName: 'Code__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Code', externalId: false })
    public code?: string | null;
    @sField({ apiName: 'Customer__r', createable: false, updateable: false, required: false, reference: () => { return Account }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customer?: Account;
    @sField({ apiName: 'Customer__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Customer', externalId: false })
    public customerId?: string | null;
    @sField({ apiName: 'Paid__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Paid', externalId: false })
    public paid?: boolean | null;
    @sField({ apiName: 'ReadyForPrint__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Ready for Print', externalId: false })
    public readyForPrint?: boolean | null;
    @sField({ apiName: 'Product__r', createable: false, updateable: false, required: false, reference: () => { return Product2 }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Product', externalId: false })
    public product?: Product2;
    @sField({ apiName: 'Product__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Product', externalId: false })
    public productId?: string | null;
    /**
     * Will be a positive integer if the project contains important items that we need to process faster
     */
    @sField({ apiName: 'PriorityBoost__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Priority Boost', externalId: false })
    public priorityBoost?: number | null;
    @sField({ apiName: 'ProjectCounter__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Project Counter', externalId: false })
    public readonly projectCounter?: number | null;

    constructor(fields?: ProjectFields, restInstance?: Rest) {
        super('Project__c', restInstance);
        this.orderItems = void 0;
        this.proofs = void 0;
        this.id = void 0;
        this.ownerId = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.lastViewedDate = void 0;
        this.lastReferencedDate = void 0;
        this.projectId = void 0;
        this.itemId = void 0;
        this.code = void 0;
        this.customer = void 0;
        this.customerId = void 0;
        this.paid = void 0;
        this.readyForPrint = void 0;
        this.product = void 0;
        this.productId = void 0;
        this.priorityBoost = void 0;
        this.projectCounter = void 0;
        this.__UUID = Project.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'Project__c' = 'Project__c';
    public readonly _TYPE_: 'Project__c' = 'Project__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<Project>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : Project.getPropertiesMeta<FieldProps<Project>, Project>(Project)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<Project>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<Project[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(Project, qryParam) : qryParam;
        return await RestObject.query<Project>(Project, qry, opts);

    }

    public static fromSFObject(sob: SObject): Project {
        return new Project().mapFromQuery(sob);
    }
}
