import { Rest, RestObject, QueryOpts, SObject, sField, SalesforceFieldType, SFLocation, SFieldProperties, FieldResolver, SOQLQueryParams, buildQuery, FieldProps, PicklistConst, CalendarDate } from "ts-force";
import { Proof, User, ProofRevision } from "./";

export type ProofReviewerFields = Partial<FieldProps<ProofReviewer>>;

/**
 * Generated class for ProofReviewer__c
 */
export class ProofReviewer extends RestObject {
    @sField({ apiName: 'QaProofs1__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'QaProofs1__r', externalId: false })
    public qaProofs1?: Proof[];
    @sField({ apiName: 'QaProofs2__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: true, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'QaProofs2__r', externalId: false })
    public qaProofs2?: Proof[];
    @sField({ apiName: 'Id', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.ID, salesforceLabel: 'Record ID', externalId: false })
    public readonly id?: string | null;
    @sField({ apiName: 'IsDeleted', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Deleted', externalId: false })
    public readonly isDeleted?: boolean | null;
    @sField({ apiName: 'Name', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.STRING, salesforceLabel: 'Reviewer Name', externalId: false })
    public name?: string | null;
    @sField({ apiName: 'CreatedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Created Date', externalId: false })
    public readonly createdDate?: Date | null;
    @sField({ apiName: 'CreatedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public createdBy?: User;
    @sField({ apiName: 'CreatedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Created By ID', externalId: false })
    public readonly createdById?: string | null;
    @sField({ apiName: 'LastModifiedDate', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Last Modified Date', externalId: false })
    public readonly lastModifiedDate?: Date | null;
    @sField({ apiName: 'LastModifiedBy', createable: false, updateable: false, required: false, reference: () => { return User }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public lastModifiedBy?: User;
    @sField({ apiName: 'LastModifiedById', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Last Modified By ID', externalId: false })
    public readonly lastModifiedById?: string | null;
    @sField({ apiName: 'SystemModstamp', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'System Modstamp', externalId: false })
    public readonly systemModstamp?: Date | null;
    @sField({ apiName: 'Proof__r', createable: false, updateable: false, required: false, reference: () => { return Proof }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof', externalId: false })
    public proof?: Proof;
    @sField({ apiName: 'Proof__c', createable: true, updateable: false, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Proof', externalId: false })
    public proofId?: string | null;
    @sField({ apiName: 'ActionTaken__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.PICKLIST, salesforceLabel: 'Action Taken', externalId: false })
    public actionTaken?: string | null;
    @sField({ apiName: 'Archived__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Archived', externalId: false })
    public archived?: boolean | null;
    @sField({ apiName: 'Complete__c', createable: true, updateable: true, required: true, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.BOOLEAN, salesforceLabel: 'Complete', externalId: false })
    public complete?: boolean | null;
    @sField({ apiName: 'Notes__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.TEXTAREA, salesforceLabel: 'Notes', externalId: false })
    public notes?: string | null;
    @sField({ apiName: 'Revision__r', createable: false, updateable: false, required: false, reference: () => { return ProofRevision }, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision', externalId: false })
    public revision?: ProofRevision;
    @sField({ apiName: 'Revision__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.REFERENCE, salesforceLabel: 'Revision', externalId: false })
    public revisionId?: string | null;
    @sField({ apiName: 'ClaimTime__c', createable: true, updateable: true, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DATETIME, salesforceLabel: 'Claim Time', externalId: false })
    public claimTime?: Date | null;
    @sField({ apiName: 'ReviewTime__c', createable: false, updateable: false, required: false, reference: undefined, childRelationship: false, salesforceType: SalesforceFieldType.DOUBLE, salesforceLabel: 'Review Time', externalId: false })
    public readonly reviewTime?: number | null;

    constructor(fields?: ProofReviewerFields, restInstance?: Rest) {
        super('ProofReviewer__c', restInstance);
        this.qaProofs1 = void 0;
        this.qaProofs2 = void 0;
        this.id = void 0;
        this.isDeleted = void 0;
        this.name = void 0;
        this.createdDate = void 0;
        this.createdBy = void 0;
        this.createdById = void 0;
        this.lastModifiedDate = void 0;
        this.lastModifiedBy = void 0;
        this.lastModifiedById = void 0;
        this.systemModstamp = void 0;
        this.proof = void 0;
        this.proofId = void 0;
        this.actionTaken = void 0;
        this.archived = void 0;
        this.complete = void 0;
        this.notes = void 0;
        this.revision = void 0;
        this.revisionId = void 0;
        this.claimTime = void 0;
        this.reviewTime = void 0;
        this.__UUID = ProofReviewer.__UUID;
        this.initObject(fields);
        return new Proxy(this, this.safeUpdateProxyHandler);
    }

    public static API_NAME: 'ProofReviewer__c' = 'ProofReviewer__c';
    public readonly _TYPE_: 'ProofReviewer__c' = 'ProofReviewer__c';
    public static __UUID = Symbol();
    private static _fields: { [P in keyof FieldProps<ProofReviewer>]: SFieldProperties; };

    public static get FIELDS() {
        return this._fields = this._fields ? this._fields : ProofReviewer.getPropertiesMeta<FieldProps<ProofReviewer>, ProofReviewer>(ProofReviewer)
    }

    public static async retrieve(qryParam: ((fields: FieldResolver<ProofReviewer>) => SOQLQueryParams) | string, opts?: QueryOpts): Promise<ProofReviewer[]> {

        const qry = typeof qryParam === 'function' ? buildQuery(ProofReviewer, qryParam) : qryParam;
        return await RestObject.query<ProofReviewer>(ProofReviewer, qry, opts);

    }

    public static fromSFObject(sob: SObject): ProofReviewer {
        return new ProofReviewer().mapFromQuery(sob);
    }
}
